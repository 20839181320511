<template>
  <v-card class="d-flex flex-column align-center pa-5 max-size">
    <div class="adiciona-button">
        <v-col v-show="!adicionar" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarAlbum" color="#0c2a69" dark>Adicionar Álbum</v-btn>
        </v-col>
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3" style="padding-top:50px" v-if="adicionar" >Adicionar Álbuns</h1>
          <h1 class="mb-3" v-if="!adicionar" >Álbuns</h1>
        </v-col>
    </div>
    <div v-if="adicionar" style="width:85%">
        <v-row>
          <v-col cols="12" sm="12" md="12" class="pa-3">
            <v-autocomplete
                v-model="editedItem.id_evento"
                :items="eventos"
                label="Evento"
                item-text="title"
                item-value="id"
                no-data-text="Nenhum Evento cadastrado"
                auto-select-first
                outlined
                hide-details
                hide-selected
              ></v-autocomplete>
          </v-col>
        </v-row>    
          
        <v-row class="pa-3">
          <v-col cols="12" sm="12" md="12">
            <v-text-field
                label="Link do álbum"
                v-model="editedItem.link"
                outlined
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-3">
          <v-col cols="12" sm="6" md="6" class="pa-2">
            <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarAlbum()"></DefaultButton>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pa-2">
            <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                          :disable="(this.editedItem.link != '' && this.editedItem.id_evento != '')?false:true"
                          @click.native="salvarNovoAlbum()"></DefaultButton>
          </v-col>
        </v-row>
    </div>
    <div style="width:85%">
      <v-data-table
          :headers="headers"
          :items="albuns"
          :sort-by="'id'"
          :sort-desc= true
          :page.sync="page"
          :items-per-page="10"
          hide-default-footer
          v-show="!adicionar"
          class="elevation-1 estilo-tabela"
          @page-count="pageCount = $event"
          :loading="loading"
          loading-text="Carregando...">
          <template v-slot:item.event.cover="{item}">
            <div style="margin-top:5px">
              <img :src="'data:image/png;base64,' + item.event.cover"  width="140" height="100"></img>
            </div>
          </template>
          <template v-slot:item.event.start_date="{item}">
            <span>
              {{formataData(item.event.start_date)}}
            </span>
          </template>
          <template v-slot:item.link="{item}">
            <div>
              <a :href="item.link" target="_blank">Link para {{item.event.title}}</a>
            </div>
          </template>
        <template v-slot:top>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">Tem certeza que quer excluir esse album?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          {{terminaLoading()}}
          <v-icon
              small
              class="mr-2 icon_acoes"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              class="icon_acoes"
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <h4 class="mb-1 mt-1">Lista de albuns vazia</h4>
        </template>
      </v-data-table>


      <div v-show="!adicionar" class="text-center pt-2">
        <v-pagination
            class="pagination"
            v-model="page"
            :length="pageCount"
        ></v-pagination>
      </div>
    </div>
  </v-card>
</template>

<script>
import axios from "../../services/axios";
import moment from "moment";

export default {
  name: 'AddAlbuns',
  components: {
    DefaultDocumentInput: () => import("@/components/DefaultComponents/DefaultDocumentInput"),
    DefaultDatePicker: () => import("@/components/DefaultComponents/DefaultDatePicker"),
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogDelete: false,
      adicionar: false,
      editar: false,
      page: 1,
      pageCount: 0,
      headers: [
        {text: 'Capa', value: 'event.cover', sortable: false,},
        {text: 'Evento', value: 'event.title', sortable: false,},
        {text: 'Data Início', value: 'event.start_date', sortable: true,},
        {text: 'Link', value: 'link', sortable: false,},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      albuns: [],
      eventos:[],
      editedIndex: -1,
      editedItem: {
        codigo: '',
        link: '',
        id_evento: '',
      },
      defaultItem: {
        codigo: '',
        link: '',
        id_evento: '',
      },
      mostra_adicionar: 1,
    }
  },
  mounted() {
    this.inicializa()
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    inicializa() {
      this.loading = true,
      axios
        .get("/albuns?with[]=event")
        .then((response) => {
          this.albuns = response.data.data;
          if(response.data.data.length == 0)this.terminaLoading();
        })
        .catch((error) => {
        });
      axios
        .get("/eventos")
        .then((response) => {
          this.eventos = response.data.data;
        })
        .catch((error) => {
        });
    },
    adicionarAlbum() {
      this.adicionar = !this.adicionar;
      this.editar = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    salvarNovoAlbum() {
        if (this.editedItem.link != '' && this.editedItem.id_evento != '') {
        const dados = {
          "event_id": this.editedItem.id_evento,
          "link": this.editedItem.link,
        }
        const dadosEdit = {
          "id": this.editedItem.codigo,
          "event_id": this.editedItem.id_evento,
          "link": this.editedItem.link,
        }
        if (this.editar != true) {
          axios
            .post("/albuns", dados)
            .then((response) => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axios
            .post(
              "/albuns" , dadosEdit)
            .then((response) => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
        }
        this.adicionarAlbum();
      }
    },
    deleteItem(item) {
      this.editedIndex = this.albuns.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    editItem(item) {
      if (this.adicionar == false) this.adicionar = true
      this.editedItem.codigo = item.id
      this.editedItem.id_evento = item.event.id
      this.editedItem.link = item.link
      this.editar = true
    },
    deleteItemConfirm() {
      axios
        .delete(
          "/albuns/" +
            this.albuns[this.editedIndex].id
        )
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
      this.albuns.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem)
        this.editedIndex = -1
      })
    },
    formataData(data_evento){
      return moment(String(data_evento)).format('DD/MM/YYYY')
    },
    terminaLoading(){
      this.loading = false
    },
  },
}
</script>

<style scoped>
*{
  padding: 0px;
  margin: 0px;
}
.estilo-tabela {
  width: 100% !important;
}

.pagination > ul > li > button > i {
  font-size: 45px !important;
}

.alinha-campos{
  padding: 12px;
  padding-left: 5px;
  padding-right: 0px;
}

.itemAnexo{
  padding: 0px 12px;
}

</style>
