<template class="fundo">
  <div class="flex align-center fundo mr-0 justify-space-around">
    <div id="logo">
      <v-img
        alt="Vuetify Logo"
        class="shrink ml-5"
        contain
        src="@/assets/images/logo.png"
        transition="scale-transition"
        width="300"
        @click="navigateTo('/')"
      />
    </div>
    <div>
      <v-card class="login" v-show="!recuperaSenha">
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
          @keyup.native.enter="submit"
        >
          <v-container>
            <v-layout column>
              <label class="titulo-login">Fazer login</label>
              <v-text-field
                outlined
                v-model="username"
                label="Usuário"
                type="text"
                required
              ></v-text-field>
              <v-text-field
                outlined
                v-model="password"
                label="Senha"
                type="password"
                required
              ></v-text-field>
              <span class="invalid" v-if="!validPassword">Usuário ou senha invalidos!</span>
            </v-layout>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-btn 
          text
          color="grey"
          @click="EsqueciMinhaSenha()">
            Esqueci Minha Senha
          </v-btn>
          <v-flex offset-xs2 mb-2>
            <v-btn class="bt-entrar" color="primary" @click="submit"
              >Entrar</v-btn
            >
          </v-flex>
        </v-card-actions>
      </v-card>

      <v-card class="login" v-show="recuperaSenha">
        <v-form
          v-model="valid"
          ref="form2"
        >
          <v-container>
            <v-layout column>
              <label v-show="!inserirToken" class="titulo-recupera">Informe seu e-mail cadastrado para redefinir sua senha:</label>
              <label v-show="inserirToken" class="titulo-recupera">Insira o código recebido via email:</label>
              <v-text-field
                v-show="!inserirToken"
                outlined
                v-model="email"
                label="E-mail de recuperação"
                type="text"
                required
                :rules="emailRules"
              ></v-text-field>
              <div v-show="inserirToken">
                <v-text-field
                  outlined
                  v-model="token"
                  label="Código"
                  type="token"
                  required
                ></v-text-field>
                <v-text-field
                  outlined
                  v-model="novaSenha"
                  label="Nova Senha"
                  type="password"
                  :rules="senhaRules"
                  required
                ></v-text-field>
                <v-text-field
                  outlined
                  v-model="confirmarNovaSenha"
                  label="Confirmar Nova Senha"
                  type="password"
                  required
                ></v-text-field>
              </div>
            </v-layout>
          </v-container>
        </v-form>
        <div v-show="enviandoEmail" class="text-center pb-3">
          <v-icon
              class="mr-2 icon_acoes mdi-spin">
              mdi-chart-donut
          </v-icon>
          Enviando e-mail...
        </div>
        <div v-show="erroCodigo" class="text-center pb-3 invalid">
          <div v-if="tentativasRestantes > 0">
            Código incorreto, tentativas restantes:  {{tentativasRestantes}}
          </div>
          <div v-if="tentativasRestantes <=0">
            Sem mais tentativas, volte para tela de login e reenvie um novo código!
          </div>
        </div>
        <div v-show="erroConfirma" class="text-center pb-3 invalid">
          As senhas não conferem!
        </div>
        <div v-show="erroEmail" class="text-center pb-3 invalid">
          O e-mail informado não está vinculado a nenhum usuário!
        </div>
        <div v-show="senhaAlterada" class="text-center pb-3 sucesso">
          Senha Alterada com sucesso!<br>
          Volte ao o Login para entrar.
        </div>
        <div v-show="inserirToken && !senhaAlterada">
          <v-btn class="bt-entrar" color="primary" @click="validaToken()" :disabled="tentativasRestantes<=0">
            Alterar senha
          </v-btn>
        </div>
        <v-card-actions>
          <v-btn 
          text
          color="grey"
          @click="EsqueciMinhaSenha()">
            Voltar para Login
          </v-btn>
          <v-flex offset-xs2 mb-2>
            <v-btn v-show="!inserirToken" class="bt-entrar" color="primary" @click="submitRecupera()" :disabled="valid"
              >Enviar</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>

    </div>
  </div>
</template>

<script>
//import axios from "axios";
import axios from "../services/axios";

export default {
  name: "Login",
  components: {},
  data: () => ({
    recuperaSenha: false,
    validPassword: true,
    inserirToken: false,
    enviandoEmail: false,
    erroConfirma: false,
    erroCodigo: false,
    senhaAlterada: false,
    erroEmail: false,
    tentativasRestantes: 3,
    username: "",
    password: "",
    novaSenha: "",
    confirmarNovaSenha: "",
    token: "",
    email: "",
    valid: true,
    emailRules: [
      (v) => !!v || "Digite um E-mail",
      (v) => /.+@.+/.test(v) || "O E-mail precisa ser válido.",
    ],
    senhaRules:[
      (v) => !!v || "Digite uma senha",
      (v) => v.length >= 6 || "A senha precisa ter 6 ou mais caracteres."
    ],
  }),
  methods: {
    submit() {
      const body = {
        username: this.username,
        password: this.password,
      };

      axios
        .post("/admin/login", body)
        .then((response) => {
          console.log(response)
          const token = response.data.access_token;
          this.validPassword = true;
          localStorage.setItem("access_token", token);
          this.$router.push("/eventos");
        })
        .catch((error) => {
          console.log(error);
          this.validPassword = false;
        });

      // axios
      //   .get("/login")
      //   .then((response) => {
      //     this.diferenciais = response.data.data;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    submitRecupera(){
      this.enviandoEmail = true;
      this.erroEmail = false;
      var email = {
        email: this.email
      }
      axios
        .post("/redefinir-senha", email)
        .then((response) => {
          if(response.mensage == "Trying to get property 'id' of non-object"){
            this.erroEmail = true;
          }else{
            this.inserirToken = true;
            this.enviandoEmail = false;
            this.erroEmail = false;
          }
        })
        .catch((error) => {
          this.erroEmail = true;
          this.enviandoEmail = false;
          console.log(error);
        });
    },
    validaToken(){
      this.erroConfirma = false;
      this.erroCodigo = false;
      
      if(this.confirmarNovaSenha == this.novaSenha){
      const dados = {
        "email": this.email,
        "temp_token": this.token,
        "password": this.novaSenha,
      }
      axios
        .post("/nova-senha", dados)
        .then((response) => {
          this.senhaAlterada = true;
        })
        .catch((error) => {
          console.log(error);
        });
      } else{
        this.erroConfirma = true;
      }
    },
    EsqueciMinhaSenha(){
      this.recuperaSenha = !this.recuperaSenha;
      this.email = "";
      this.novaSenha = "";
      this.confirmarNovaSenha = "";
      this.emailToken = "";
      this.token = "";
      this.erroConfirma = false;
      this.erroCodigo = false;
      this.inserirToken = false;
      this.tentativasRestantes = 3;
      this.senhaAlterada = false;
      this.erroEmail = false;
    },
    navigateTo(where) {
        if(this.$router.history.current.path != ('/' + where)){
        this.$router.push({path: where})
        }
    }
  },
};
</script>

<style>
.fundo {
  height: 100vh;
  width: 100%;
  background-image: url("../assets/images/background.png");
  background-position: center;
  background-size: cover;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
  position: relative;
}
.v-application .ml-5 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.container {
  width: 330px;
}

.flex {
  max-width: 2000px !important;
}

.bt-entrar{
  display: flex;
  margin: auto;
}

.login{
  margin: auto;
  max-width: 400px !important;
}

.EsqueciMinhaSenha{
  color: red;
}

.titulo-login{
  margin: auto;
  padding: 10px;
  padding-bottom: 20px;
  font: normal normal 30px Comic, sans-serif;
}

.titulo-recupera{
  padding: 10px;
  padding-bottom: 20px;
  font: normal normal 19px Comic, sans-serif;
}

.invalid{
  color:red;
  padding-left: 20px;
  padding-right: 20px;
}

.sucesso{
  color:rgb(110, 218, 39);
  padding-left: 20px;
  padding-right: 20px;
}
</style>
