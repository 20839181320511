<template>
  <v-card class="d-flex flex-column align-center pa-5 max-size">
    <table style="width:100%">
      <tr>
        <td style="width:25%">
          <v-btn class="box estilo-botao" @click="mostraAdministrador()">
            <h4>Administradores</h4>
          </v-btn>
        </td>
        <td style="width:25%">
          <v-btn class="box estilo-botao" @click="mostraTermo()">
            <h4>Termo</h4>
          </v-btn>
        </td>
        <td style="width:25%">
          <v-btn class="box estilo-botao" @click="mostraCategoria()">
            <h4>Categorias</h4>
          </v-btn>
        </td>
        <td style="width:25%">
          <v-btn class="box estilo-botao" @click="mostraDivisoes()">
            <h4>Divisões</h4>
          </v-btn>
        </td>
      </tr>
    </table>
    
    <div style="width: 100%;" v-show="mostraAdministradores">
      <div class="adiciona-button">
        <v-col v-show="!adicionar" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarUsuario" color="#0c2a69" dark>Adicionar Admin</v-btn>
        </v-col>
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3" style="padding-top:50px" v-if="adicionar" >Adicionar Administrador</h1>
          <h1 class="mb-3" v-if="!adicionar" >Administradores do Portal</h1>
        </v-col>
      </div>
      <div v-if="adicionar" style="width:85%; margin:auto;">
          <v-row class="pa-3">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  label="Nome"
                  v-model="editedItem.nome"
                  outlined
                  hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  label="E-mail"
                  v-model="editedItem.email"
                  outlined
                  :disabled="editar"
                  hide-details="auto"
                  :rules="emailRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-3">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  label="Senha"
                  v-model="editedItem.senha"
                  :disabled="editar"
                  outlined
                  hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  label="Perfil"
                  v-model="search"
                  outlined
                  hide-details
                  disabled
              ></v-text-field>
            </v-col>
          </v-row>
    
          
          <v-row class="pa-3">
            <v-col cols="12" sm="6" md="6" class="pa-2">
              <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarUsuario()"></DefaultButton>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-2">
              <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                            :disable="(this.editedItem.nome != '' && this.editedItem.senha != '' && this.editedItem.email != '')?false:true"
                            @click.native="salvarNovoUsuario()"></DefaultButton>
            </v-col>
          </v-row>
      </div>
      <div style="width:85%; margin:auto;" >
        <v-data-table
            :headers="headers"
            :items="usuarios"
            :page.sync="page"
            :items-per-page="7"
            :sort-by="'id'"
            item-key="codigo"
            v-show="!adicionar"
            hide-default-footer
            class="elevation-1 estilo-tabela"
            @page-count="pageCount = $event"
            :loading="loadingUsuarios"
            loading-text="Carregando..."
            :search="search">

            <template v-slot:top>
                        <v-dialog v-model="dialogDelete" max-width="550px">
                          <v-card>
                            <v-card-title class="headline">Tem certeza que quer excluir esse usuário?</v-card-title>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                              <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                          <!--<v-dialog v-model="dialogSenha" max-width="550px">
                            <v-card>
                              <v-card-title class="headline">Tem certeza que quer redefinir esta senha?</v-card-title>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeResetSenha">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="editSenhaConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>-->
                        </template>

            <template v-slot:item="{ item }">
                <tr
                v-show="item.profile == 0">
                  <td class="text-center">{{item.id}}</td>
                  <td class="text-left">{{item.name}}</td>
                  <td class="text-left">{{item.username}}</td>
                  <td class="text-left">{{item.email}}</td>
                  <td class="text-left">{{formataData(item.created_at)}}</td>
                  <!--<td class="text-left">{{item.customer}}</td>-->
                  <td class="text-left">{{item.profile_name}}</td>
                  <td class="text-left">
                    {{terminaLoadingUsuarios()}}
                    <v-icon
                        small
                        class="mr-2 icon_acoes"
                        title="Editar"
                        @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        class="mr-2 icon_acoes"
                        title="Excluir"
                        @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>

            <template v-slot:no-data>
              <h4 class="mb-1 mt-1">Lista de usuários vazia</h4>
            </template>
            
        </v-data-table>
        <div v-show="!adicionar && mostraAdministradores" class="text-center pt-2">
          <v-pagination
              class="pagination"
              v-model="page"
              :length="pageCount"
          ></v-pagination>
        </div>
      </div>
      <DefaultDialog
          :dialog="dialog"
          :title="(editar != true)?'Adicionado com sucesso':'Editado com sucesso'"
          :text="(editar != true)?'O usuário foi adicionado com sucesso.':'O usuário foi editado com sucesso.'"
          @update:closeDialog="dialog = $event"
      />
    </div>

    <div v-show="mostraTermos" style="width: 85%;">
      <div class="adiciona-button">
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3" style="padding-top:50px" v-if="editaTermo" >Editar Termo</h1>
        </v-col>
      </div>
      <div v-if="!editaTermo" class="termo">
        <h1 class="mb-3 text-center" v-if="!editaTermo">{{termo.title}}</h1>
        {{termo.text}}
      </div>
      <div v-if="editaTermo">
         <v-row class="pa-tituloEtapas">
          <v-col cols="12" sm="12" md="12" class="pa-3">
              <v-text-field
                label="Título"
                v-model="termo.title"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pa-3">
              <v-textarea
                height="28em"
                name="input-7-1"
                label="Adicione texto referente ao termo"
                v-model="termo.text"
                hide-details
                outlined>
              </v-textarea>
            </v-col>
          </v-row>
          <br>
          <v-row class="mb-3">
            <v-col cols="12" sm="6" md="6" class="pa-2">
              <DefaultButton :cor="1" :text="'Cancelar'" @click.native="editarTermo()"></DefaultButton>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-2">
              <DefaultButton text="Salvar Edição"
                            :disable="(this.termo.title != '' && this.termo.text != '')?false:true"
                            @click.native="salvarTermo()"></DefaultButton>
            </v-col>
          </v-row>
      </div>
      <v-row>
      <v-col v-show="!editaTermo" cols="12" class="align-center  ma-auto pa-7" style="padding:0px">
          <v-btn v-on:click="editarTermo" class="estilo-botao" dark>Editar Termo</v-btn>
      </v-col>
      </v-row>
    </div>

    <div v-show="mostraCategorias" style="width: 100%;">
      <div class="adiciona-button">
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3" style="padding-top:50px" >Categorias</h1>
        </v-col>
      </div>
      <div style="width:85%" class="ma-auto">
          <v-row>
            <br>
              <div style="width:80%" class="ma-auto box-add mt-5 mb-5">
                <v-data-table
                  v-if="categorias != ''"
                  :headers="headersCategorias"
                  :items="categorias"
                  :sort-by="'id'"
                  :items-per-page="1000"
                  hide-default-footer
                  class="elevation-1 estilo-tabela">

                  <template v-slot:top>
                    <v-dialog v-model="dialogDeleteCategoria" max-width="540px">
                      <v-card>
                        <v-card-title class="headline">Tem certeza que quer excluir essa categoria?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDeleteCategoria">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteCategoriaConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                    v-show="item.id != -1"
                    small
                    class="mr-2 icon_acoes"
                    @click="editCategoria(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon
                    v-show="item.id != -1"
                    small
                    class="icon_acoes"
                    @click="deleteCategoria(item)">
                      mdi-delete
                    </v-icon>
                    <v-icon
                    v-show="item.id == -1"
                    small
                    class="mr-2 icon_acoes"
                    title="Categoria Fixa">
                    mdi-close-box-outline
                    </v-icon>
                  </template>
                </v-data-table>
                <div v-show="categorias.length == 0">
                  <h4 class="mb-1 mt-1">Nenhuma categoria cadastrada</h4>
                </div>
              </div>
              <div style="width: 80%;" class="box-add ma-auto mb-4">
                <div @click="mostraAddCategoria = !mostraAddCategoria" class="estilo-botao pa-1">Adicionar categoria</div>
                <v-text-field
                v-show="mostraAddCategoria"
                class="ma-auto pa-2"
                label="Nome da categoria"
                v-model="editedCategoria.nome"
                hide-details
                filled
                ></v-text-field>
                <v-btn v-show="mostraAddCategoria" style="width: 40%;" class="estilo-botao pa-1 ma-auto mb-2" @click="addCategoria()">Salvar</v-btn>
              </div>
        </v-row>
      </div>
    </div>

    <div v-show="mostraDivisao" style="width: 100%;">
      <div class="adiciona-button">
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3" style="padding-top:50px" >Divisões</h1>
        </v-col>
      </div>
      <div style="width:85%" class="ma-auto">
        <v-row class="mb-8">
          <v-col cols="12" sm="6" md="6" class="pa-3 ma-auto" >
              <v-autocomplete
                v-model="tipoEventoSelect"
                :items="tipoEventos"
                label="Tipo de evento"
                item-text="name"
                item-value="id"
                no-data-text="Nenhum evento encontrado"
                auto-select-first
                outlined
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <br>
              <div style="width:80%" class="ma-auto box-add mt-0 mb-5">
                <v-data-table
                  v-if="divisaoSelect.length != 0"
                  :headers="headersDivisoes"
                  :items="divisaoSelect"
                  :sort-by="'id'"
                  :items-per-page="1000"
                  hide-default-footer
                  class="elevation-1 estilo-tabela">

                  <template v-slot:top>
                    <v-dialog v-model="dialogDeleteDivisao" max-width="520px">
                      <v-card>
                        <v-card-title class="headline">Tem certeza que quer excluir essa divisão?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDeleteDivisao">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteDivisaoConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2 icon_acoes"
                        @click="editDivisao(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        class="icon_acoes"
                        @click="deleteDivisao(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
                <div v-show="divisaoSelect.length == 0">
                  <h4 class="mb-1 mt-1">Nenhuma divisão cadastrada</h4>
                </div>
              </div>
              <div style="width: 80%;" class="box-add ma-auto mb-4">
                <div @click="mostraAddDivisao = !mostraAddDivisao" class="estilo-botao pa-1">Adicionar divisão</div>
                <v-text-field
                v-show="mostraAddDivisao"
                class="ma-auto pa-2"
                label="Nome da divisão"
                v-model="editedCategoria.nome"
                hide-details
                filled
                ></v-text-field>
                <v-textarea
                  v-show="mostraAddDivisao"
                  class="ma-auto pa-2"
                  name="input-7-1"
                  label="Descrição da divisão"
                  v-model="editedCategoria.descricao"
                  hide-details
                  filled
              ></v-textarea>
                <v-btn v-show="mostraAddDivisao" style="width: 40%;" class="estilo-botao pa-1 ma-auto mb-2" @click="addDivisao()">Salvar</v-btn>
              </div>
        </v-row>
      </div>
    </div>
    
  </v-card>
</template>

<script>
import axios from "../../services/axios";
import moment from "moment";

export default {
  name: 'AddUsuarios',
  components: {
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
    DefaultDialog: () => import("@/components/DefaultComponents/DefaultDialog"),
  },
  data() {
    return {
      loadingUsuarios: false,
      dialog: false,
      //dialogSenha: false,
      dialogDelete: false,
      dialogDeleteCategoria: false,
      dialogDeleteDivisao: false,
      adicionar: false,
      editar: false,
      mostraAdministradores: true,
      mostraTermos: false,
      mostraCategorias: false,
      mostraDivisao: false,
      editaTermo: false,
      mostraAddCategoria: false,
      mostraAddDivisao: false,
      page: 1,
      pageCount: 0,
      search: 'Administrador',
      headers: [
        {text: 'Código',value: 'id'},
        {text: 'Nome',value: 'name'},
        {text: 'Usuário ',value: 'username'},
        {text: 'E-mail', value: 'email', sortable: true},
        {text: 'Data cadastro', value: 'created_at', sortable: true},
        //{text: 'Sou associado', value: 'customer', sortable: true},
        {text: 'Tipo', value: 'profile_name', sortable: true},
        {text: 'Ações', value: 'actions', sortable: false, width: '10%'},
      ],
      headersCategorias: [
        {text: 'Código',value: 'id', sortable: false, width: '20%'},
        {text: 'Nome',value: 'title', sortable: false},
        {text: 'Ações', value: 'actions', sortable: false, width: '20%'},
      ],
      headersDivisoes: [
        {text: 'Código',value: 'id', sortable: false, width: '20%'},
        {text: 'Nome',value: 'title', sortable: false},
        {text: 'Descrição',value: 'description', sortable: false},
        {text: 'Ações', value: 'actions', sortable: false, width: '20%'},
      ],
      emailRules: [
      (v) => !!v || "Digite um E-mail",
      (v) => /.+@.+/.test(v) || "O E-mail precisa ser válido",
      ],
      usuarios: [],
      tipoEventos: [],
      tipoEventoSelect: "",
      termo: {},
      categorias: [],
      divisoes: [],
      divisaoSelect: [],
      editedCategoria: {
        nome: '',
        id: null,
        descricao: "",
      },
      defaultCategoria: {
        nome: '',
        id: null,
        descricao: '',
      },
      editedIndex: -1,
      editedItem: {
        nome: '',
        usuario: '',
        email: '',
        codigo: '',
        senha: '',
        created_at: '',
      },
      defaultItem: {
        nome: '',
        usuario: '',
        email: '',
        codigo: '',
        senha: '',
        created_at: '',
      },
    }
  },
  mounted() {
    this.inicializa()
  },
  watch: {
    tipoEventoSelect(){
      for(var i=0;i<this.divisoes.length;i++){
        if(this.divisoes[i].cod_event_type == this.tipoEventoSelect){
          this.divisaoSelect = this.divisoes[i].divisions
        }
      }
      this.editedCategoria = Object.assign({}, this.defaultCategoria);
    }
  },
  methods: {
    inicializa() {
      this.loadingUsuarios = true,
      axios
        .get("/usuarios")
        .then((response) => {
          this.usuarios = response.data.data.map((usuarios) => ({
            ...usuarios, // Association, same as Object.assign
          }));
          if(response.data.data.length == 0)this.terminaLoading();
        })
        .catch((error) => {
          console.log(error);
        });
      
      axios
        .get("/termos")
        .then((response) => {
          this.termo = response.data.data
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/events-type")
        .then((response) => {
          this.tipoEventos = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("/categorias")
        .then((response) => {
          this.categorias = response.data.data
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("/divisoes")
        .then((response) => {
          this.divisoes = response.data.data
        })
        .catch((error) => {
          console.log(error);
        });
    },
    navigateTo(where) {
        if(this.$router.history.current.path != ('/' + where)){
        this.$router.push({path: where})
        }
    },
    adicionarUsuario() {
      this.adicionar = !this.adicionar;
      if (this.editar == true) this.editar = false;
      this.editedItem = this.defaultItem;
    },
    editarTermo(){
      this.editaTermo = !this.editaTermo;
    },
    salvarNovoUsuario() {
      if (this.editedItem.nome != '' && this.editedItem.senha != '' && this.editedItem.email != '') {
        this.dialog = true
        const dados = {
          "id": this.editedItem.codigo,
          "name": this.editedItem.nome,
          "username": this.editedItem.email,
          "email": this.editedItem.email,
          "password": this.editedItem.senha,
          "profile": '0',
        };
        if (this.editar != true) {
          axios
            .post(
              "/usuarios",
              dados,
              {
                headers: {},
              }
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
            this.adicionar = !this.adicionar;
        } else {
          axios
            .put("/usuarios/" +
              this.editedItem.codigo,
              dados,
              {headers: {},}
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
          this.adicionar = !this.adicionar;
        }
      }
    },
    salvarTermo(){
      if(this.termo.title != '' && this.termo.text != ''){
        const dados = {
          "id": this.termo.id,
          "text": this.termo.text,
          "title": this.termo.title,
        };
        axios
        .post("/termos",dados)
        .then((response) => {
          this.inicializa();
          this.editarTermo();
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    deleteItem(item) {
      this.editedIndex = this.usuarios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    /*editSenha(item) {
      this.editedIndex = this.usuarios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogSenha = true
    },
    editSenhaConfirm() {
      this.dialogSenha = false
      const dados = {
          "user_id": this.editedItem.codigo,
        };
          axios
            .post("/usuarios/reset-password",
              dados,
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
    },*/
    editItem(item) {
      if (this.adicionar == false) this.adicionar = true
      this.editedItem.codigo = item.id
      this.editedItem.nome = item.name
      this.editedItem.usuario = item.username
      this.editedItem.email = item.email
      this.editar = true
    },
    deleteItemConfirm() {
      axios
          .delete(
              "/usuarios/" +
              this.usuarios[this.editedIndex].id
          )
          .then(() => {
          })
          .catch((error) => {
            console.log(error);
          });
      this.usuarios.splice(this.editedIndex, 1)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    /*closeResetSenha() {
      this.dialogSenha = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },*/
    terminaLoadingUsuarios(){
      this.loadingUsuarios = false
    },
    formataData(data) {
      return moment(String(data)).format('DD/MM/YYYY')
    },
    formataValor(valor){
      return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    zeraMostra(){
      this.mostraAdministradores = false;
      this.mostraTermos = false;
      this.mostraCategorias = false;
      this.mostraDivisao = false;
      this.editaTermo = false;
      this.editedCategoria = Object.assign({}, this.defaultCategoria);
      this.tipoEventoSelect = 1;
      this.mostraAddCategoria = false;
      this.mostraAddDivisao = false;
    },
    mostraAdministrador(){
      this.zeraMostra();
      this.mostraAdministradores = true;
    },
    mostraTermo(){
      this.zeraMostra();
      this.mostraTermos = true;
    },
    mostraCategoria(){
      this.zeraMostra();
      this.mostraCategorias = true;
    },
    mostraDivisoes(){
      this.zeraMostra();
      this.mostraDivisao = true;
    },
    editCategoria(item){
      this.editedCategoria.nome = item.title
      this.editedCategoria.id = item.id
      this.editedCategoria.descricao = item.description
      this.mostraAddCategoria = true
    },
    addCategoria(){
      if(this.editedCategoria.nome != ''){
        const dados = {
          "id": this.editedCategoria.id,
          "title": this.editedCategoria.nome,
          "event_type_id": this.tipoEventoSelect,
        };
        axios
        .post("/categorias",dados)
        .then((response) => {
          axios
          .get("/categorias")
          .then((response) => {
            this.categorias = response.data.data
            this.editedCategoria = Object.assign({}, this.defaultCategoria);
            this.mostraAddCategoria = false
          })
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    deleteCategoria(item){
      this.editedIndex = this.categorias.indexOf(item)
      this.editedCategoria = Object.assign({}, item)
      this.dialogDeleteCategoria = true
    },
    deleteCategoriaConfirm(){
      axios
        .delete("/categorias/" + this.editedCategoria.id)
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
      this.categorias.splice(this.editedIndex, 1);
      this.closeDeleteCategoria();
    },
    closeDeleteCategoria(){
      this.dialogDeleteCategoria = false
      this.$nextTick(() => {
      this.editedCategoria = Object.assign({}, this.defaultCategoria);
      this.editedIndex = -1;
      })
    },
    addDivisao(){
       if(this.editedCategoria.nome != ''){
        const dados = {
          "id": this.editedCategoria.id,
          "title": this.editedCategoria.nome,
          "description": this.editedCategoria.descricao,
          "event_type_id": this.tipoEventoSelect,
        };
        axios
        .post("/divisoes",dados)
        .then((response) => {
          axios
          .get("/divisoes")
          .then((response) => {
            this.divisoes = response.data.data
            for(var i=0;i<this.divisoes.length;i++){
              if(this.divisoes[i].cod_event_type == this.tipoEventoSelect){
                this.divisaoSelect = this.divisoes[i].divisions
              }
            }
            this.editedCategoria = Object.assign({}, this.defaultCategoria);
            this.mostraAddDivisao = false
          })
          .catch((error) => {
            console.log(error);
          });
        })
        .catch((error) => {
          console.log(error);
        });
        
      }
    },
    editDivisao(item){
      this.editedCategoria.nome = item.title
      this.editedCategoria.id = item.id
      this.editedCategoria.descricao = item.description
      this.mostraAddDivisao = true
    },
    deleteDivisao(item){
      this.editedIndex = this.divisaoSelect.indexOf(item)
      this.editedCategoria = Object.assign({}, item)
      this.dialogDeleteDivisao = true
    },
    deleteDivisaoConfirm(){
      axios
        .delete("/divisoes/" + this.editedCategoria.id)
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
      this.divisaoSelect.splice(this.editedIndex, 1);
      this.closeDeleteDivisao();
    },
    closeDeleteDivisao(){
      this.dialogDeleteDivisao = false
      this.$nextTick(() => {
      this.editedCategoria = Object.assign({}, this.defaultCategoria);
      this.editedIndex = -1;
      })
    },
  },
}
</script>

<style scoped>
.estilo-tabela {
  width: 100% !important;
}

.pagination > ul > li > button > i {
  font-size: 45px !important;
}

.status{
  width:100%;
  justify-content: center
}

.box{
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.box-add{
  border: solid 2px rgba(0, 0, 0, 0.308);
  border-style: solid solid solid solid;
  margin: 0px;
  width: 100%;
  height: 100%;
}

.itens-tabela{
  border: solid 2px rgba(0, 0, 0, 0.39);
  border-style: none solid solid solid;
  margin: 0px;
  width: 100%;
}

.itens-tabela-excluir{
  border: solid 2px rgba(0, 0, 0, 0.39);
  border-style: none none none solid;
  margin: 0px;
}

.termo{
  width:85%; 
  margin:auto;
  padding: 20px;
  border: solid 1px rgba(0, 0, 0, 0.500);
  background-color: rgba(228, 228, 228, 0.658);
  text-align: justify;
  margin-top: 10px;
}

.btm-add-divisao{
  border: solid 2px rgba(0, 0, 0, 0.500);
  padding: 0px;
  margin: auto;
}

.estilo-botao:hover{
  cursor: pointer;
  color: white !important;
  background-color: #13398a !important;
}

.estilo-botao-add{
  color: white !important;
  background-color: #0c2a69 !important;
}

</style>
