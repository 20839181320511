<template>
  <v-card :max-width="tamanhoTela">
    <v-carousel cycle height="auto" hide-delimiters>
      <v-carousel-item
        v-for="(banner, i) in banners"
        :key="i"
        :src="banner.imagem"
        class="tamanho"
      />
      <!--    :src="'https://creativeapi.schaffenlab.com/api/download?image='+banner.imagem"-->
    </v-carousel>
  </v-card>
</template>

<script>
  import primeiro_banner from "@/assets/images/banners/CTHilbert01.jpg";
  import segundo_banner from "@/assets/images/banners/CTHilbert02.jpg";
  import terceiro_banner from "@/assets/images/banners/CTHilbert03.jpg";

export default {
  name: "Banners",
  data: () => ({
    tamanhoTela: window.innerWidth,
    banners: [
      { imagem: primeiro_banner },
      { imagem: segundo_banner },
      { imagem: terceiro_banner },
    ],
  }),
  methods:{
  }
};
</script>

<style>
.tamanho {
  padding-top: 48px;
}
</style>
