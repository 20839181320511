<template>
  <v-card class="d-flex flex-column align-center pa-5 max-size">
   <div class="adiciona-button">
        <v-col v-show="!adicionar  && !mostraDetalhes" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarEvento" color="#0c2a69" dark>Adicionar Evento</v-btn>
        </v-col>
        <v-col v-show="mostraDetalhes" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="abreMostrarDetalhes" color="#0c2a69" dark>Voltar</v-btn>
        </v-col>
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3" style="padding-top:50px" v-if="adicionar" >Adicionar Eventos</h1>
          <h1 class="mb-3" v-if="!adicionar" >Eventos</h1>
        </v-col>
    </div>
    <div v-if="adicionar && !mostraDetalhes" style="width:85%">
        <v-row class="pa-DI pt-7">
          <div class="pa-3">
            <v-col  v-if="editedItem.imagem64 != ''" cols="12" sm="4" md="4">
              <div class="box-img">
                <img class="img-evento" :src="'data:image/png;base64,' + editedItem.imagem64" width="280" height="200"></img> 
              </div>
            </v-col>
            <v-col  v-else cols="12" sm="4" md="4">
              <div class="box-img">
                <img class="img-evento" src="../../assets/images/sem_imagem.png" ></img>
              </div>
            </v-col>
          </div>
            <v-col cols="12" sm="7" md="7">
              <DefaultImageInput
                style="width:100%"
                class="itemAnexo"
                text="Alterar imagem de capa"
                :required="true"
                type="Evento"
                module="editedItem.arquivo"
                reqText="Selecione o arquivo"
                hide-details
                @update:returnFile="editedItem.arquivo = $event"
              />
            </v-col>
        </v-row>
        <v-row class="pa-6" style="width:98%">
          <v-col cols="12" sm="3" md="3" class="pa-3 box-switch1">
            <div class="text-right">Envia Push:</div>
          </v-col>
          <v-col class="text-left pt-3 box-switch2" cols="12" sm="3" md="3">
            <v-switch
              v-model="editedItem.enviaPush"
              inset
              hide-details
              :label="`${editedItem.enviaPush == true ? ' Sim' : ' Não'}`"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="pa-3 box-switch3">
            <div class="text-right">Documentos</div>
            <div class="text-right">Obrigatórios:</div>
          </v-col>
          <v-col class="text-left pt-3 box-switch4" cols="12" sm="3" md="3">
            <v-switch
              v-model="editedItem.documentosObrigatorios"
              inset
              hide-details
              :label="`${editedItem.documentosObrigatorios == true ? ' Sim' : ' Não'}`"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row style="width: 97%;" class="pl-3">
          <v-col cols="12" sm="8" md="8" style="margin:auto">
            <v-text-field
                label="Título"
                v-model="editedItem.titulo"
                outlined
                hide-details
            ></v-text-field>
          </v-col>
            <v-col cols="12" sm="4" md="4" class="pa-3">
              <v-select
                  :items="tipoEventos"
                  label="Tipo do Evento"
                  item-text="name"
                  item-value="cod_event_type"
                  v-model="editedItem.tipoEvento"
                  outlined
                  hide-details
              ></v-select>
            </v-col>
          </v-row>
        <v-row style="width: 98%;">
          <v-col cols="12" sm="6" md="6" class="pa-3">
            <DefaultDatePicker
              label="Data Início"
              :data="(editedItem.data_inicio == '')? '': formataData(editedItem.data_inicio)"
              @update:returnDate="editedItem.data_inicio = $event"/>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-3">
            <DefaultDatePicker
              label="Data Final"
              :data="(editedItem.data_fim == '')? '': formataData(editedItem.data_fim)"
              @update:returnDate="editedItem.data_fim = $event"/>
            </v-col>
          </v-row>
          <v-row style="width: 98%;">
            <v-col cols="12" sm="6" md="6" class="pa-3">
              <v-text-field
                type= number
                label="Valor para sócios"
                v-model="editedItem.valorSocio"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-3">
              <v-text-field
                type= number
                label="Valor para não sócios"
                v-model="editedItem.valorNaoSocio"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row style="width: 98%;">
            <v-col cols="12" sm="6" md="6" class="pa-3">
              <v-text-field
                type= text
                label="Voucher ID"
                v-model="editedItem.voucherID"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-3">
              <v-text-field
                type= number
                label="Valor para voucher"
                v-model="editedItem.valorVoucher"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-3">
          <v-col cols="12" sm="12" md="12" style="margin:auto">
            <v-text-field
                label="Link para download do PFD de informações"
                v-model="editedItem.urlPDF"
                outlined
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-3">
          <v-col cols="12" sm="12" md="12" style="margin:auto">
            <v-text-field
                label="Link para download dos Resultados"
                v-model="editedItem.urlResultados"
                outlined
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>
          <v-row class="pa-3">
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                  name="input-7-1"
                  label="Adicione texto referente ao evento"
                  v-model="editedItem.descricao"
                  hint="Insira uma mensagem detalhada sobre o comunciado"
                  outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="!editarEtapa">
            <v-col cols="12" sm="6" md="6" class="pa-2">
              <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarEvento()"></DefaultButton>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-2">
              <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                            :disable="(this.editedItem.titulo != '' && this.editedItem.descricao != '' && this.editedItem.data_inicio != null && this.editedItem.tipoEvento != '' && this.editedItem.arquivo != '' && this.editedItem.valorSocio != ''  && this.editedItem.valorNaoSocio != '')?false:true"
                            @click.native="salvarNovoEvento()"></DefaultButton>
            </v-col>
        </v-row>
        <div v-if="editarEtapa">
          <h1 class="mb-5">Etapas</h1>
            <v-data-table
              :headers="headersEtapas"
              :items="editedItem.event_stages"
              :sort-by="['start_date']"
              hide-default-footer
              v-show="editarEtapa"
              class="elevation-1 estilo-tabela">

                <template v-slot:top>
                <v-dialog v-model="dialogDeleteEtapa" max-width="460px">
                  <v-card>
                    <v-card-title class="headline">Tem certeza que quer excluir a etapa?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDeleteEtapa">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteEtapaConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>

              <template v-slot:item.start_date="{item}">
                <span>
                  {{formataData(item.start_date)}}
                </span>
              </template>
              <template v-slot:item.end_date="{item}">
                <span v-show="item.end_date != 'Data inválida'">{{formataData(item.end_date)}}</span>
              </template>
            
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2 icon_acoes"
                title="Editar"
                @click="editEtapas(item)">
                mdi-pencil
              </v-icon>
              <v-icon
                small
                class="mr-2 icon_acoes"
                title="Excluir"
                @click="deleteEtapa(item)">
                mdi-delete
              </v-icon>
              <v-icon
                v-show="item.link_result != null"
                small
                class="icon_acoes"
                title="Resultados"
                @click="abrelink(item.link_result)">
                mdi-checkbox-multiple-marked
              </v-icon>
            </template>
            <template v-slot:no-data>
              <h4 class="mb-1 mt-1">Lista de etapas vazia</h4>
            </template>
          </v-data-table>
          <br>
          <div v-if="mostraEditEtapa">
            <v-card>
              <v-row class="pa-tituloEtapas">
                <v-col cols="12" sm="12" md="12" class="pa-3">
                    <v-text-field
                      label="Título"
                      v-model="etapaItem.titulo"
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              <v-row class="pa-infoEtapa">
                <v-col cols="12" sm="4" md="4" class="pa-3">
                  <DefaultDatePicker
                    label="Data Início"
                    :data="(etapaItem.data_inicio == '')? '': formataData(etapaItem.data_inicio)"
                    @update:returnDate="etapaItem.data_inicio = $event"/>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pa-3">
                  <DefaultDatePicker
                    label="Data Final"
                    :data="(etapaItem.data_fim == '')? '': formataData(etapaItem.data_fim)"
                    @update:returnDate="etapaItem.data_fim = $event"/>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pa-3">
                  <v-text-field
                    type="number"
                    label="N.º Participantes"
                    v-model="etapaItem.maxParticipantes"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pa-url">
                <v-col cols="12" sm="12" md="12" class="pa-3">
                    <v-text-field
                      label="Link para donwload dos Resultados"
                      v-model="etapaItem.urlResultados"
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              <v-row class="pa-DI">
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    name="input-7-1"
                    label="Descrição"
                    v-model="etapaItem.descricao"
                    hint="Insira uma descrição detalhada sobre a etapa"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
              <div class="pa-3 pa-salvarEtapas pb-5">
                <DefaultButton text="Salvar Etapa"
                            :disable="(
                              this.etapaItem.titulo != '' 
                              && this.etapaItem.data_inicio != '' 
                              && this.etapaItem.data_fim != '' 
                              && this.etapaItem.maxParticipantes != ''  
                              && this.etapaItem.descricao != ''
                            )?false:true"
                            @click.native="salvarEtapas()"></DefaultButton>
              </div>
            </v-card>
          </div>
          <div class="pa-3" v-if="!mostraEditEtapa">
            <v-btn
              elevation="2"
              @click="addEtapas()"
            >Adicionar Etapa</v-btn>
          </div>
          <br>
          <v-row v-if="editarEtapa">
            <v-col cols="12" sm="6" md="6" class="pa-2">
              <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarEvento()"></DefaultButton>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-2">
              <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                            :disable="(this.editedItem.titulo != '' && this.editedItem.descricao != '' && this.editedItem.data_inicio != null && this.editedItem.tipoEvento != '' && this.editedItem.arquivo != '' && this.editedItem.valorSocio != ''  && this.editedItem.valorNaoSocio != '')?false:true"
                            @click.native="salvarNovoEvento()"></DefaultButton>
            </v-col>
          </v-row>
        </div>
    </div>
    <div style="width:85%">
      <v-card style="width:100%">
        <v-card-title style="" v-show="!adicionar && !mostraDetalhes">
          <v-col cols="12" sm="2" md="2" class="align-right">
            <span >Filtrar:</span>
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="12" sm="8" md="8" class="pa-4">
              <v-select
                  :items="tipoEventos"
                  label="Tipo do Evento"
                  item-text="name"
                  item-value="name"
                  v-model="search"
                  hide-details
              ></v-select>
            </v-col>
            <v-col v-show="!adicionar  && !mostraDetalhes" cols="12" sm="2" md="2">
            <v-btn v-on:click="search='n'" color="#0c2a69" dark>Ver Todos</v-btn>
          </v-col>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="eventos"
            :sort-by="'id'"
            :sort-desc= true
            :page.sync="page"
            :items-per-page="10"
            hide-default-footer
            v-show="!adicionar  && !mostraDetalhes"
            class="elevation-1 estilo-tabela"
            @page-count="pageCount = $event"
            :loading="loading"
            loading-text="Carregando...">

          <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="470px">
              <v-card>
                <v-card-title class="headline">Tem certeza que quer excluir o evento?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item="{ item }">
              {{terminaLoading()}}
              <tr :v-if="search !== 'n'" v-show="item.type === search">
                <td>{{item.id}}</td>
                <td>
                  <div style="margin-top:5px">
                    <img :src="'data:image/png;base64,' + item.image"  width="140" height="100"></img>
                  </div>
                </td>
                <td>{{item.title}}</td>
                <td class="Dica">
                  {{item.description.substr(0, 29)}} <span v-if="item.description.length > 30">...</span>
                  <div class="DicaTexto">{{item.description}}</div>
                </td>
                <td>
                  <span>{{formataData(item.start_date)}}</span><br>
                  <span v-show="item.end_date != null">até</span><br>
                  <span v-show="item.end_date != null">{{formataData(item.end_date)}}</span>
                </td>
                <td>{{item.type}}</td>
                <td>
                  <span>S:{{formataValor(item.partner_price)}}</span><br>
                  <span>N:{{formataValor(item.price)}}</span>
                </td>
                <td>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  title="Editar"
                  @click="editItem(item)">
                  mdi-pencil
                  </v-icon>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  title="Excluir"
                  @click="deleteItem(item)">
                  mdi-delete
                  </v-icon>
                  <v-icon
                  v-show="item.link != null"
                  small
                  class="mr-2 icon_acoes"
                  title="Informações"
                  @click="abrelink(item.link)">
                  mdi-information
                  </v-icon>
                  <v-icon
                  v-show="item.link_result != null"
                  small
                  class="mr-2 icon_acoes"
                  title="Resultados"
                  @click="abrelink(item.link_result)">
                  mdi-checkbox-multiple-marked
                  </v-icon>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  title="Detalhes"
                  @click="abreMostrarDetalhes(item.id)">
                  mdi-magnify
                  </v-icon>
                </td>
              </tr>
              <tr v-show="search == 'n'">
                <td>{{item.id}}</td>
                <td>
                  <div style="margin-top:5px">
                    <img :src="'data:image/png;base64,' + item.image"  width="140" height="100"></img>
                  </div>
                </td>
                <td>{{item.title}}</td>
                <td class="Dica">
                  {{item.description.substr(0, 29)}} <span v-if="item.description.length > 30">...</span>
                  <div class="DicaTexto">{{item.description}}</div>
                </td>
                 <td>
                  <span>{{formataData(item.start_date)}}</span><br>
                  <span v-show="item.end_date != null">até</span><br>
                  <span v-show="item.end_date != null">{{formataData(item.end_date)}}</span>
                </td>
                <td>{{item.type}}</td>
                <td>
                  <span>S:{{formataValor(item.partner_price)}}</span><br>
                  <span>N:{{formataValor(item.price)}}</span>
                </td>
                <td>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  title="Editar"
                  @click="editItem(item)">
                  mdi-pencil
                  </v-icon>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  title="Excluir"
                  @click="deleteItem(item)">
                  mdi-delete
                  </v-icon>
                  <v-icon
                  v-show="item.link != null"
                  small
                  class="mr-2 icon_acoes"
                  title="Informações"
                  @click="abrelink(item.link)">
                  mdi-information
                  </v-icon>
                  <v-icon
                  v-show="item.link_result != null"
                  small
                  class="mr-2 icon_acoes"
                  title="Resultados"
                  @click="abrelink(item.link_result)">
                  mdi-checkbox-multiple-marked
                  </v-icon>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  title="Detalhes"
                  @click="abreMostrarDetalhes(item.id)">
                  mdi-magnify
                  </v-icon>
                </td>
              </tr>
            </template>

          <template v-slot:no-data>
            <h4 class="mb-1 mt-1">Lista de eventos vazia</h4>
          </template>
        </v-data-table>
      </v-card>
    <div v-show="!adicionar  && !mostraDetalhes" class="text-center pt-2">
      <v-pagination
          class="pagination"
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
    </div>

    <div style="width:85%;" v-show="mostraDetalhes" class="text-left">
      <div v-if="eventoDetalhesCarregado">
        <div>
          <v-row>
            <v-col cols="12" sm="8" md="8">
              <table style="width:97%">
                <tr class="box box-detalhes pa-3 tam-detalhes mb-5">
                  <td style="width:60%">
                    <span><b>Nome: </b>{{eventoDetalhado.title}}</span><br>
                    <span><b>Tipo: </b>{{eventoDetalhado.type}}</span><br>
                    <span><b>Data de início: </b>{{formataData(eventoDetalhado.start_date)}}</span><br>
                    <span><b>Data final: </b>{{formataData(eventoDetalhado.end_date)}}</span><br>
                    <span><b>Valor para sócios: </b>{{formataValor(eventoDetalhado.partner_price)}}</span><br>
                    <span><b>Valor para não sócios: </b>{{formataValor(eventoDetalhado.price)}}</span><br>
                  </td>
                  <td style="width:30%" class="box-detalhes pa-3 tam-detalhes mb-5 text-center">
                    <b>Voucher</b><br>
                    <b>ID: </b>{{eventoDetalhado.voucher_id}}<br>
                    <b>Valor: </b>{{formataValor(eventoDetalhado.voucher_price)}}
                  </td>
                </tr>
              </table>
            </v-col>
            
            <v-col cols="12" sm="4" md="4">
              <a :href="eventoDetalhado.link" target="_blank">
                <div class="box pa-3 box-btn fonte1 text-center">
                    Informações
                </div>
              </a>
              <br>
              <a :href="eventoDetalhado.link_result" target="_blank">
                <div class="box pa-3 box-btn fonte1 text-center">
                    Resultados
                </div>
              </a>
            </v-col>
          
        </v-row>

          <div class="ml-20 pa-2 text-center box3 ma-left">
            <button @click="mostraEtapas()">
            <h4>Etapas</h4>
            </button>
          </div>
          <div class="pa-2 text-center box5">
            <button @click="mostraParticipantes()">
            <h4>Participantes</h4>
            </button>
          </div>
          <div class="pa-2 text-center box4">
            <button @click="mostraFinanceiros()">
            <h4>Financeiro</h4>
            </button>
          </div>
          <div class="box2 mb-4">
            <div class="text-center" v-if="mostraEtapa">
              <div v-if="eventoDetalhado.event_stages != undefined">
                  <v-data-table
                  :headers="headersEtapasDetalhes"
                  :items="eventoDetalhado.event_stages"
                  :sort-by="'id'"
                  hide-default-footer
                  class="elevation-1 estilo-tabela"
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  item-key="id"
                  show-expand>

                  <template v-slot:no-data>
                    <h4 class="mb-1 mt-1">Este evento não possui etapas cadastradas!</h4>
                  </template>

                  <template v-slot:item.id="{ item }">
                    <div class="text-center">
                      {{item.id}}
                    </div>
                  </template>

                  <template v-slot:item.description="{ item }">
                    <div class="Dica">
                      {{item.description.substr(0, 29)}} <span v-if="item.description.length > 30">...</span>
                      <div class="DicaTexto">{{item.description}}</div>
                    </div>
                  </template>

                  <template v-slot:item.start_date="{ item }">
                    <span>
                      {{formataData(item.start_date)}}
                    </span>
                  </template>

                  <template v-slot:item.end_date="{ item }">
                    <span>
                      {{formataData(item.end_date)}}
                    </span>
                  </template>

                  <template v-slot:item.vagas="{ item }">
                    <div class="text-center">
                      {{item.vagas}}
                    </div>
                  </template>

                  <template v-slot:item.inscritos.length="{ item }">
                    <div class="text-center">
                      {{item.inscritos.length}}
                    </div>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-6">
                      <v-data-table
                        :headers="headersDetalhes"
                        :items="item.inscritos"
                        :sort-by="'name'"
                        hide-default-footer
                        class="elevation-1 estilo-tabela">

                        <template v-slot:no-data>
                          <h4 class="mb-1 mt-1">Esta etapa não possui participantes cadastrados!</h4>
                        </template>

                        <template v-slot:item.id="{ item }">
                          <div class="text-center">
                            {{item.id}}
                          </div>
                        </template>

                        <template v-slot:item.pagamento_confirmado="{ item }">
                          <div class="text-left">
                            <span v-if="item.pagamento_confirmado == 0">
                              Pendente
                            </span>
                            <span v-else>
                              OK
                            </span>
                          </div>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <div class="text-left">
                            <v-icon
                            v-if="item.pagamento_confirmado == 0"
                            class="icon_acoes"
                            title="Confirmar Pagamento"
                            @click="confirmarPagamento(item)">
                              mdi-currency-usd
                            </v-icon>
                            <v-icon
                            v-if="item.pagamento_confirmado != 0"
                            class="icon_acoes"
                            title="Cancelar Pagamento"
                            @click="cancelarPagamento(item)">
                              mdi-currency-usd-off
                            </v-icon>
                          </div>
                        </template>

                      </v-data-table>
                    </td>
                  </template>
                  <template v-slot:item.resultados="{ item }">
                    <div class="text-center">
                      <v-icon
                      v-show="item.link_result != null"
                      small
                      class="mr-2 icon_acoes"
                      title="Resultados"
                      @click="abrelink(item.link_result)">
                      mdi-checkbox-multiple-marked
                      </v-icon>
                      <v-icon
                      v-show="item.link_result == null"
                      small
                      class="mr-2 icon_acoes"
                      title="Sem resultados cadastrados">
                      mdi-close-box-outline
                      </v-icon>
                    </div>
                  </template>
              </v-data-table>
              </div>
            </div>
            <div class="text-center" v-if="mostraParticipante">
              <v-data-table
                  :headers="headersParticipantes"
                  :items="eventoDetalhado.participants"
                  :sort-by="'name'"
                  :page.sync="pageParticipantes"
                  :items-per-page="10"
                  hide-default-footer
                  class="elevation-1 estilo-tabela"
                  @page-count="pageCountParticipantes = $event">

                  <template v-slot:no-data>
                    <h4 class="mb-1 mt-1">Este evento não possui participantes cadastrados!</h4>
                  </template>

                  <template v-slot:item.id="{ item }">
                    <div class="text-center">
                      {{item.id}}
                    </div>
                  </template>

                  <template v-slot:item.pagamento_confirmado="{ item }">
                    <div class="text-left">
                      <span v-if="item.pagamento_confirmado == 0">
                        Pendente
                      </span>
                      <span v-else>
                        OK
                      </span>
                    </div>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <div class="text-left">
                      <v-icon
                      v-if="item.pagamento_confirmado == 0"
                      class="icon_acoes"
                      title="Confirmar Pagamento"
                      @click="confirmarPagamento(item)">
                        mdi-currency-usd
                      </v-icon>
                      <v-icon
                      v-if="item.pagamento_confirmado != 0"
                      class="icon_acoes"
                      title="Cancelar Pagamento"
                      @click="cancelarPagamento(item)">
                        mdi-currency-usd-off
                      </v-icon>
                    </div>
                  </template>

                </v-data-table>
                <div class="text-center pt-2">
                  <v-pagination
                      class="pagination"
                      v-model="pageParticipantes"
                      :length="pageCountParticipantes"
                  ></v-pagination>
                </div>

              </div>
              <div class="text-center" v-if="mostraFinanceiro">
                <v-data-table
                :headers="headersFinanceiro"
                :items="movimentacoes"
                :sort-by="'id'"
                :sort-desc= true
                :page.sync="page"
                :items-per-page="10"
                :search="eventoDetalhado.title"
                :loading="loadingFinanceiro"
                hide-default-footer
                v-show="!adicionar"
                class="elevation-1 estilo-tabela"
                @page-count="pageCount = $event"
                loading-text="Carregando...">

                <template v-slot:item="{ item }">
                  <tr :style="getColor(item.categoria)">
                    <td>{{item.id}}</td>
                    <td class="text-left">{{item.evento.title}}</td>
                    <td class="text-left">{{item.descricao}}</td>
                    <td>{{formataData(item.data_pagamento)}}</td>
                    <td>{{formataValor(item.valor)}}</td>
                    <td>{{item.categoria}}</td>
                    <td class="text-left">{{item.nome_usuario}}</td>
                  </tr>
                </template>

                <template v-slot:footer>
                  <div class="total-financeiro" v-show="!loadingFinanceiro">
                    Saldo Total do Evento: <div class="valor-total"  :style="getColorTotal(eventoDetalhado.total_financeiros)"><b>{{formataValor(eventoDetalhado.total_financeiros)}}</b></div>
                  </div>
                </template>

              <template v-slot:no-data>
                <h4 class="mb-1 mt-1">Lista de movimentações vazia</h4>
              </template>
            </v-data-table>
            <div v-show="!adicionar" class="text-center pt-2">
              <v-pagination
                  class="pagination"
                  v-model="page"
                  :length="pageCount"
              ></v-pagination>
            </div>
                  </div>
                </div>
              </div>
            </div>
          <div v-else class="text-center">
            <v-icon
                class="mr-2 icon_acoes mdi-spin">
                mdi-chart-donut
            </v-icon>
            Carregando informações...</div>
        </div>
    <DefaultDialog
        :dialog="dialog"
        :title="(editar != true)?'Adicionado com sucesso':'Editado com sucesso'"
        :text="(editar != true)?'Seu evento foi adicionado com sucesso.':'Seu evento foi editado com sucesso.'"
        @update:closeDialog="dialog = $event"
    />
    <DefaultDialog
        :dialog="dialogErro"
        :title="'Ocorreu um erro'"
        :text="erro + 'Não foi possível cadastrar/editar esse evento, tente novamente ou abra um chamado na Schaffen.'"
        @update:closeDialog="dialogErro = $event"
    />
  </v-card>
</template>

<script>
import axios from "../../services/axios";
import moment from "moment";

export default {
  name: 'AdmEventos',
  components: {
    DefaultImageInput: () => import("@/components/DefaultComponents/DefaultImageInput"),
    DefaultDocumentInput: () => import("@/components/DefaultComponents/DefaultDocumentInput"),
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
    DefaultDatePicker: () => import("@/components/DefaultComponents/DefaultDatePicker"),
    DefaultDialog: () => import("@/components/DefaultComponents/DefaultDialog"),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogDelete: false,
      dialogDeleteEtapa: false,
      dialogErro: false,
      adicionar: false,
      editar: false,
      editarEtapa: false,
      novaEtapa: false,
      mostraEditEtapa: false,
      mostraDetalhes:false,
      eventoDetalhesCarregado: false,
      mostraEtapa: true,
      mostraParticipante: false,
      mostraFinanceiro: false,
      loadingFinanceiro: true,
      expanded: [],
      singleExpand: true,
      search: 'n',
      search_aux: 'n',
      noDataCont: 0,
      page: 1,
      pageCount: 0,
      pageParticipantes: 1,
      pageCountParticipantes: 0,
      headers: [
        {text: 'Código', sortable: true, value: 'id', width:"9%"},
        {text: 'Imagem capa', value: 'capa', sortable: false,},
        {text: 'Titulo', sortable: false, value: 'title'},
        {text: 'Descrição', value: 'description', sortable: false},
        {text: 'Data', sortable: true, value: 'start_date'},
        {text: 'Tipo', value: 'type', sortable: false},
        {text: 'Valor', value: 'price', sortable: false},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      headersEtapas: [
        {text: 'Código', sortable: false, value: 'id'},
        {text: 'Título', value: 'title', sortable: false},
        {text: 'Descrição', value: 'description', sortable: false},
        {text: 'Data Início', sortable: true, value: 'start_date'},
        {text: 'Data Fim', sortable: true, value: 'end_date'},
        {text: 'N.º Participantes', value: 'participants', sortable: false},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      headersEtapasDetalhes: [
        {text: 'Código', sortable: false, value: 'id'},
        {text: 'Título', value: 'title', sortable: false},
        {text: 'Descrição', value: 'description', sortable: false},
        {text: 'Data Início', sortable: false, value: 'start_date'},
        {text: 'Data Fim', sortable: false, value: 'end_date'},
        {text: 'N.º Participantes', value: 'inscritos.length', sortable: false},
        {text: 'Vagas Restantes', value: 'vagas', sortable: false},
        {text: 'Participantes', value: 'data-table-expand', sortable: false},
        {text: 'Resultados', value: 'resultados', sortable: false},
      ],
      headersDetalhes: [
        {text: 'Código',value: 'id', width: '11%'},
        {text: 'Nome',value: 'name'},
        {text: 'E-mail', value: 'email', sortable: true},
        {text: 'Categoria', value: 'category.title', sortable: false},
        {text: 'Divisão', value: 'division.title', sortable: false},
        {text: 'Voucher', value: 'voucher_id', sortable: false},
        {text: 'Valor', value: 'price_to_pay', sortable: false},
        {text: 'Pagamento', value: 'pagamento_confirmado', sortable: true, width: "13%"},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      headersParticipantes: [
        {text: 'Código',value: 'id', width: '11%'},
        {text: 'Nome',value: 'name'},
        {text: 'E-mail', value: 'email', sortable: true},
        {text: 'Voucher', value: 'voucher_id', sortable: true},
        {text: 'Valor', value: 'price_to_pay', sortable: true},
        {text: 'Pagamento', value: 'pagamento_confirmado', sortable: true},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      headersFinanceiro: [
        {text: 'Código', sortable: false, value: 'id'},
        {text: 'Evento', value: 'evento.title', sortable: false},
        {text: 'Descrição', value: 'descricao', sortable: false},
        {text: 'Data', sortable: true, value: 'data_pagamento'},
        {text: 'Valor', sortable: true, value: 'valor'},
        {text: 'Categoria', sortable: true, value: 'categoria', width:"11%"},
        {text: 'Usuário', sortable: true, value: 'nome_usuario'},
      ],
      eventos: [],
      tipoEventos: [],
      eventoDetalhado: [],
      movimentacoes: [],
      user: [],
      etapaItemNovo: {},
      erro: '',
      editedIndex: -1,
      editedEtapaIndex: -1,
      editedItem: {
        codigo: '',
        titulo: '',
        descricao: '',
        data_inicio: '',
        data_fim: '',
        tipoEvento: '',
        arquivo: '',
        imagem64: '',
        valorSocio: '',
        valorNaoSocio: '',
        valorVoucher: '',
        voucherID: '',
        urlResultados: '',
        urlPDF: '',
        enviaPush: true,
        documentosObrigatorios: false,
        event_stages: [],
      },
      defaultItem: {
        codigo: '',
        titulo: '',
        descricao: '',
        data_inicio: '',
        data_fim: '',
        tipoEvento: '',
        arquivo: '',
        imagem64: '',
        valorSocio: '',
        valorNaoSocio: '',
        valorVoucher: '',
        voucherID: '',
        urlResultados: '',
        urlPDF: '',
        enviaPush: true,
        documentosObrigatorios: false,
        event_stages: [],
      },
      etapaItem: {
        titulo: "",
        codigo: '',
        descricao: '',
        data_inicio: '',
        data_fim: '',
        maxParticipantes: '',
        urlResultados: '',
       },
      defaultEtapaItem: {
        titulo: "",
        codigo: '',
        descricao: '',
        data_inicio: '',
        data_fim: '',
        maxParticipantes: '',
        urlResultados: '',
       },
    }
  },
  mounted() {
    this.inicializa()
  },
  methods: {
    inicializa() {
      this.editarEtapa = false,
      this.loading = true,
      axios
        .get("/eventos?with[]=event_stages")
        .then((response) => {
          this.eventos = response.data.data;
          if(response.data.data.length == 0)this.terminaLoading();
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/events-type")
        .then((response) => {
          this.tipoEventos = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/user")
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formataData(data) {
      return moment(String(data)).format('DD/MM/YYYY')
    },
    formataValor(valor){
      return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    terminaLoading(){
      this.loading = false
    },
    adicionarEvento() {
      this.adicionar = !this.adicionar;
      if (this.adicionar == false) {
        this.inicializa();
        this.editedItem = Object.assign({}, this.defaultItem);
      }
      if (this.editar == true) {
        this.editar = false
        this.editarEtapa = false
        this.novaEtapa = false
        this.mostraEditEtapa = false
        this.editedItem = Object.assign({}, this.defaultItem);
      }
    },
    salvarNovoEvento() {
      if (this.editedItem.titulo != '' && this.editedItem.descricao != '' && this.editedItem.data_inicio != null && this.editedItem.tipoEvento != '' && this.editedItem.arquivo != '' && this.editedItem.valorSocio != ''  && this.editedItem.valorNaoSocio != '') {
        this.editedItem.valorSocio.replace(",",".");
        this.editedItem.valorNaoSocio.replace(",",".");
        var dados = {};
        if(this.editedItem.valorVoucher == '')
          this.editedItem.valorVoucher = null;
        if(this.editedItem.voucherID == '')
          this.editedItem.voucherID = null;
        if(this.editedItem.arquivo == -1){
          dados = {
          "title": this.editedItem.titulo,
          "description": this.editedItem.descricao,
          "event_type_id": this.editedItem.tipoEvento,
          "start_date": moment(this.editedItem.data_inicio).format("YYYY-MM-DD"),
          "end_date": this.editedItem.data_fim,
          "image": this.editedItem.imagem64,
          "partner_price":this.editedItem.valorSocio,
          "price":this.editedItem.valorNaoSocio,
          "id": this.editedItem.codigo,
          "link": this.editedItem.urlPDF,
          "link_result": this.editedItem.urlResultados,
          "send": this.editedItem.enviaPush,
          "requires_documents": this.editedItem.documentosObrigatorios,
          "archeive_pdf_result" : "",
          "voucher_price": this.editedItem.valorVoucher,
          "voucher_id": this.editedItem.voucherID,
          };
        }else{
          dados = {
          "title": this.editedItem.titulo,
          "description": this.editedItem.descricao,
          "event_type_id": this.editedItem.tipoEvento,
          "start_date": moment(this.editedItem.data_inicio).format("YYYY-MM-DD"),
          "end_date": this.editedItem.data_fim,
          "image": this.editedItem.arquivo[0].base64,
          "partner_price":this.editedItem.valorSocio,
          "price":this.editedItem.valorNaoSocio,
          "id": this.editedItem.codigo,
          "archieve_pdf": this.editedItem.urlPDF,
          "link": this.editedItem.urlPDF,
          "link_result": this.editedItem.urlResultados,
          "send": this.editedItem.enviaPush,
          "requires_documents": this.editedItem.documentosObrigatorios,
          "archeive_pdf_result" : "",
          "voucher_price": this.editedItem.valorVoucher,
          "voucher_id": this.editedItem.voucherID,
          };
        }
        if (this.editar != true) {
          axios
            .post(
              "/eventos",
              dados,
              {
                headers: {},
              }
            )
            .then(response => {
              if (response === undefined) {
                this.erro = "A descrição ultrapassa a quantidade permitida de caracteres, 540. ";
                this.dialogErro = true;
              } else{
                if(!this.editarEtapa){
                  this.editarEtapa = true;
                  this.editedItem.codigo = response.data.data.id;
                }else{
                  this.inicializa();
                  this.adicionar = !this.adicionar;
                  this.erro = false;
                  this.editedItem = Object.assign({}, this.defaultItem);
                }
              }
            })
            .catch(error => {
              console.log(error);
              this.dialogErro = true;
            });
        } else {
          axios
            .put(
              "/eventos/" +
                this.editedItem.codigo,
              dados,
              {
                headers: {},
              }
            )
            .then(response => {
              if (response === undefined) {
                this.erro = "A descrição ultrapassa a quantidade permitida de caracteres, 540. ";
                this.dialogErro = true;
              } else{
              this.inicializa();
              this.adicionar = !this.adicionar;
              this.erro = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              }
            })
            .catch(error => {
              console.log(error);
              this.dialogErro = true;
            });
        }
      }
    },
    addEtapas(){
      this.mostraEditEtapa = true
      this.novaEtapa = true
    },
    salvarEtapas(){
      if (this.etapaItem.titulo != '' 
          && this.etapaItem.data_inicio != '' 
          && this.etapaItem.data_fim != '' 
          && this.etapaItem.maxParticipantes != ''  
          && this.etapaItem.descricao != ''){
            const dados = {
            "id": this.etapaItem.codigo,
            "events_id": this.editedItem.codigo,
            "title": this.etapaItem.titulo,
            "description":this.etapaItem.descricao,
            "start_date": moment(this.etapaItem.data_inicio).format("YYYY-MM-DD"),
            "end_date": moment(this.etapaItem.data_fim).format("YYYY-MM-DD"),
            "participants": this.etapaItem.maxParticipantes,
            "link_result": this.etapaItem.urlResultados,
            "archieve_pdf": '',
            };
          /*if (this.novaEtapa == true) {*/
            axios
              .post(
                "/events-stage", dados
              )
              .then(response => {
                if (response === undefined) {
                  this.erro = "A descrição ultrapassa a quantidade permitida de caracteres, 540. ";
                  this.dialogErro = true;
                } else{
                this.etapaItemNovo = response.data.data;
                this.erro = false;
                this.mostraEditEtapa = false
                this.atualizaTabela();
                this.etapaItem = Object.assign({}, this.defaultEtapaItem);
                }
              })
              .catch(error => {
                console.log(error);
                this.dialogErro = true;
              });
          /*} else {
            axios
              .put(
                "/events-stage/" +
                  this.etapaItem.codigo,
                dados
              )
              .then(response => {
                if (response === undefined) {
                  this.erro = "A descrição ultrapassa a quantidade permitida de caracteres, 540. ";
                  this.dialogErro = true;
                } else{
                this.etapaItemNovo = response.data.data;
                this.erro = false;
                this.mostraEditEtapa = false;
                this.atualizaTabela();
                this.etapaItem = Object.assign({}, this.defaultEtapaItem);
                }
              })
              .catch(error => {
                console.log(error);
                this.dialogErro = true;
              });*/
          }
    },
    atualizaTabela(){
      if(this.editar == true){
        this.editedItem.event_stages.splice(this.editedEtapaIndex, 1, this.etapaItemNovo);
      }else{
        this.editedItem.event_stages.splice(this.editedItem.event_stages.length, 0, this.etapaItemNovo);
      }
    },
    editEtapas(etapa){
      if (this.editarEtapa == false) this.editarEtapa = true
      if (this.mostraEditEtapa == false) this.mostraEditEtapa = true
      if (this.novaEtapa == true) this.mostraEditEtapa = false
      this.etapaItem.codigo = etapa.id
      this.etapaItem.descricao = etapa.description
      this.etapaItem.data_inicio = etapa.start_date
      this.etapaItem.data_fim = etapa.end_date
      this.etapaItem.maxParticipantes = etapa.participants
      this.etapaItem.urlResultados = etapa.link_result
      this.etapaItem.titulo = etapa.title

    },
    deleteItem(item) {
      this.editedIndex = this.eventos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteEtapa(item) {
      this.editedEtapaIndex = this.editedItem.event_stages.indexOf(item)
      this.etapaItem = Object.assign({}, item)
      this.dialogDeleteEtapa = true
    },
    editItem(item) {
      window.scrollTo(0, 0);
      if (this.adicionar == false) this.adicionar = true
      this.editedItem.codigo = item.id
      this.editedItem.titulo = item.title
      this.editedItem.descricao = item.description
      for(var i = 0; i < this.tipoEventos.length; i++){
        if(item.type == this.tipoEventos[i].name){
          this.editedItem.tipoEvento = this.tipoEventos[i].cod_event_type
        }
      }
      this.editedItem.data_inicio = item.start_date
      this.editedItem.data_fim = item.end_date
      this.editedItem.imagem64 = item.image
      this.editedItem.arquivo = -1
      this.editedItem.event_stages = item.event_stages
      this.editedItem.valorSocio = item.partner_price
      this.editedItem.valorNaoSocio = item.price
      this.editedItem.urlResultados = item.link_result
      this.editedItem.urlPDF = item.link
      this.editedItem.valorVoucher = item.voucher_price
      this.editedItem.voucherID = item.voucher_id

      this.editarEtapa = true
      this.editar = true
    },
    deleteItemConfirm() {
      axios
        .delete(
          "/eventos/" +
            this.eventos[this.editedIndex].id
        )
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
      this.eventos.splice(this.editedIndex, 1);
      this.closeDelete();
    },
     deleteEtapaConfirm() {
      axios
        .delete(
          "/events-stage/" +
            this.editedItem.event_stages[this.editedEtapaIndex].id
        )
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
      this.editedItem.event_stages.splice(this.editedEtapaIndex, 1);
      this.closeDeleteEtapa();
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.etapaItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDeleteEtapa() {
      this.dialogDeleteEtapa = false
      this.$nextTick(() => {
        this.edited = Object.assign({}, this.defaultEtapaItem)
        this.editedEtapaIndex = -1
      })
    },
    showMessage(){   
      let mostraDescricao = document.querySelector(".mostraDescricao") ;
      mostraDescricao.style.display = "block";   
    },
    hideMessage(){
      let mostraDescricao = document.querySelector(".mostraDescricao") ;
      mostraDescricao.style.display = "none"; 
    },
    abrelink(link){
      window.open(link);
    },
    abreMostrarDetalhes(id_evento){
      this.mostraDetalhes = !this.mostraDetalhes;
      if(this.mostraDetalhes == true){
       axios
        .get("/evento-detalhes/"+ id_evento)
        .then((response) => {
          this.eventoDetalhado = response.data.data;
            this.eventoDetalhesCarregado = true;
        })
        .catch((error) => {
          console.log(error);
        });
      }
      else{
        this.eventoDetalhesCarregado = false;
        this.mostraEtapas();
      }
    },
    mostraEtapas(){
      this.mostraParticipante = false;
      this.mostraFinanceiro = false;
      this.mostraEtapa = true;
    },
    mostraParticipantes(){
      this.mostraEtapa = false;
      this.mostraFinanceiro = false;
      this.mostraParticipante = true;
    },
    mostraFinanceiros(){
      this.mostraEtapa = false;
      this.mostraParticipante = false;
      this.mostraFinanceiro = true;
      axios
        .get("/financeiro")
        .then((response) => {
          this.movimentacoes = response.data.data;
            this.loadingFinanceiro = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColor(categoria) {
      if (categoria == "D") return 'background-color:rgb(243, 208, 208)'
      else if (categoria == "C") return 'background-color:rgb(217, 235, 217)'
    },
    getColorTotal(valor) {
      if (valor < 0){
        return 'color:red'
      } else if(valor > 0){ 
        return 'color:green'
      } else {
        return 'color:black'
      }
    },
    confirmarPagamento(item){
      var description = "Taxa de inscrição do usuário " + item.name;
      if(item.voucher_id != null){
        description = description + ", Voucher: " + item.voucher_id;
      }
      const dados2 = {
        "user_id": this.user.id,
        "events_id": this.eventoDetalhado.id,
        "description": description,
        "payday": new Date(),
        "category": "C",
        "value": item.price_to_pay,
      };
        axios
        .post(
          "/financeiro",
          dados2,
        )
        .then(response => {
          const dados = {
            "user_event_id": item.user_event_id,
          };
          axios
            .post(
              "/paid-events-user",
              dados,
            )
            .then(response => {
              axios
                .get("/evento-detalhes/"+ this.eventoDetalhado.id)
                .then((response) => {
                  this.eventoDetalhado = response.data.data;
                    this.eventoDetalhesCarregado = true;
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
          this.dialogErro = true;
        });
      
    },
    cancelarPagamento(item){
      var description = "Cancelamento de taxa de inscrição do usuário " + item.name;
      if(item.voucher_id != null){
        description = description + ", Voucher: " + item.voucher_id;
      }
      const dados2 = {
        "user_id": this.user.id,
        "events_id": this.eventoDetalhado.id,
        "description": description,
        "payday": new Date(),
        "category": "D",
        "value": item.price_to_pay,
      };
        axios
        .post(
          "/financeiro",
          dados2,
        )
        .then(response => {
          const dados = {
            "user_event_id": item.user_event_id,
          };
          axios
            .post(
              "/paid-events-user",
              dados,
            )
            .then(response => {
              axios
                .get("/evento-detalhes/"+ this.eventoDetalhado.id)
                .then((response) => {
                  this.eventoDetalhado = response.data.data;
                    this.eventoDetalhesCarregado = true;
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
          this.dialogErro = true;
        });
    },
  },
}
</script>

<style scoped>
*{
  padding: 0px;
  margin: 0px;
}

.estilo-tabela {
  width: 100% !important;
}

.pagination > ul > li > button > i {
  font-size: 45px !important;
}

.align-right{
  text-align: right;
}

.pa-DI{
  padding-left: 25px;
  width: 98%;
}

.pa-infoEtapa{
  padding: 12px;
  padding-right: 25px;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
}

.pa-tituloEtapas{
  padding: 12px;
  padding-right: 5px;
  padding-bottom: 0px;
}

.pa-url{
  padding: 12px;
  padding-top: 0px;
  padding-right: 5px;
  padding-bottom: 10px;
}

.pa-salvarEtapas{
  width: 200px;
  margin: auto;
}

.Dica{
  position: relative; 
}

.Dica:hover .DicaTexto{
  visibility: visible;
}

.DicaTexto{
  position: absolute; 
  visibility: hidden; 
  font-size: 13px; 
  width: 400px; 
  background: rgb(0, 0, 0); 
  color:#fff; 
  text-align: left; 
  padding: 5px  5px; 
  border-radius: 6px; 
  bottom: 100%; left: 50%; 
  margin-left: 20px;
  margin-bottom: -40px;
}

.DicaTexto:after{
  content: " "; 
  position: absolute; 
  top: 100%; left: 5%; 
  margin-left: -5px; 
  border-width: 5px; 
  border-style: solid; 
  border-color: #000 transparent transparent transparent;
}

.box-btn{
  color: white !important;
  background-color: #0c2a69 !important;
  height: 80px;
  font-size:30px ;
  font-weight: 500;
  width: 100%;
  margin-bottom: 15px !important;
}

.box-btn:hover{
  cursor: pointer;
  background-color: #13398a !important;
}

.box-detalhes{
  background-color: rgb(236, 236, 236);
}

.box{
  border: solid 2px rgba(0, 0, 0, 0.500);
  border-radius: 0.25em;
  padding: 0px;
  display: inline-block;
}

.box-switch1{
  border: solid 1px rgba(0, 0, 0, 0.500);
  border-style: solid none solid solid;
  border-radius: 0.25em 0em 0em 0.25em;
  padding: 25px !important ;
  margin: auto;
}

.box-switch2{
  border: solid 1px rgba(0, 0, 0, 0.500);
  border-style: solid none solid none;
  padding: 0px;
  padding: 25px !important ;
  margin: auto;
}

.box-switch3{
  border: solid 1px rgba(0, 0, 0, 0.500);
  border-style: solid none solid solid;
  padding: 0px;
  padding: 13px !important ;
  margin: auto;
}

.box-switch4{
  border: solid 1px rgba(0, 0, 0, 0.500);
  border-style: solid solid solid none;
  border-radius: 0em 0.25em 0.25em 0em;
  padding: 0px;
  padding: 25px !important ;
  margin: auto;
}

.box-img{
  border: solid 2px rgba(0, 0, 0, 0.500);
  border-radius: 0.25em;
  padding: 0px;
  width: 280px;
  height: 200px;
  display: block;

}

.img-evento{
  display: inherit;
  width: 276px;
  height: 196px;
}

.box2{
  border: solid 2px rgba(0, 0, 0, 0.500);
  border-radius: 0em 0.25em 0.25em 0.25em;
}

.box3{
  border: solid 2px rgba(0, 0, 0, 0.500);
  border-radius: 0.25em 0em 0em 0em;
  background-color: rgb(236, 236, 236);
  width: 150px;
  display: inline-block;
}

.box4{
  border: solid 2px rgba(0, 0, 0, 0.500);
  border-radius: 0em 0.25em 0em 0em;
  background-color: rgb(236, 236, 236);
  width: 150px;
  display: inline-block;
}

.box5{
  border: solid 2px rgba(0, 0, 0, 0.500);
  border-radius: 0em 0em 0em 0em;
  background-color: rgb(236, 236, 236);
  width: 150px;
  display: inline-block;
}

.box3:hover{
  background-color: rgb(175, 175, 175);
}

.box4:hover{
  background-color: rgb(175, 175, 175);
}

.box5:hover{
  background-color: rgb(175, 175, 175);
}

.tam-detalhes{
  width: 100%;
}

.itemAnexo{
  margin-right: 20px;
}

.total-financeiro{
  height: 50px;
  padding: 0px 30px;
  padding-top: 10px;
  font-size: 20px;
  text-align: center;
}

.valor-total{
  display: inline;
}

</style>

<style>
.mdi-chevron-down{
  color: rgb(15, 15, 161) !important;
}
</style>
