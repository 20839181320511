<template>
  <v-card class="d-flex flex-column align-center pa-5 max-size">
   <div class="adiciona-button">
        <v-col v-show="!adicionar" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarComunicado" color="#0c2a69" dark>Adicionar Comunicado</v-btn>
        </v-col>
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3" style="padding-top:50px" v-if="adicionar" >Adicionar Comunicados</h1>
          <h1 class="mb-3" v-if="!adicionar" >Comunicados</h1>
        </v-col>
    </div>
    <div v-if="adicionar" style="width:85%">
        <v-row class="pa-3">
          <v-col cols="12" sm="12" md="12" style="margin:auto">
            <v-text-field
                label="Título"
                v-model="editedItem.titulo"
                outlined
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row style="width: 98%;">
            <v-col cols="12" sm="6" md="6" class="pa-3">
              <v-autocomplete
                v-model="editedItem.id_evento"
                :items="eventos"
                label="Evento"
                item-text="title"
                item-value="id"
                no-data-text="Nenhum evento encontrado"
                auto-select-first
                outlined
                hide-details
                hide-selected
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-3">
            <v-select
                :items="etapas"
                :no-data-text="etapaMensagem"
                label="Etapa"
                item-text="title"
                item-value="id"
                v-model="editedItem.id_etapa"
                outlined
                hide-details
            ></v-select>
          </v-col>
          </v-row>
        <v-row style="width: 99%;">
          <v-col cols="12" sm="6" md="6" class="pa-3">
            <DefaultDatePicker
              :label="labelData"
              :data="(editedItem.data == '')? '': formataData(editedItem.data)"
              @update:returnDate="editedItem.data = $event"/>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="switch pa-3">
              <div style="width: 30%; margin: auto">
                <label>Envia Push:</label>
                <v-switch
                  v-model="enviaPush"
                  inset
                  hide-details
                  :label="`${enviaPush == true ? ' Sim' : ' Não'}`"
                ></v-switch>
              </div>
            </v-col>
          </v-row>
          <v-row class="pa-3">
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                  name="input-7-1"
                  label="Adicione texto referente ao comunicado"
                  v-model="editedItem.descricao"
                  hint="Insira uma mensagem detalhada sobre o comunciado"
                  outlined
              ></v-textarea>
            </v-col>
          <v-col cols="12" sm="6" md="6" class="pa-2">
            <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarComunicado()"></DefaultButton>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pa-2">
            <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                          :disable="(this.editedItem.titulo != '' && this.editedItem.descricao != '' && this.editedItem.data != null && this.editedItem.tipo_evento_id != '' && this.editedItem.id_evento != null)?false:true"
                          @click.native="salvarNovoComunicado()"></DefaultButton>
          </v-col>
        </v-row>
    </div>
    <div style="width:85%">
      <v-card style="width:100%">
        <v-card-title style="" v-show="!adicionar">
          <v-col cols="12" sm="2" md="2" class="align-right">
            <span >Filtrar:</span>
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="12" sm="8" md="8" class="pa-4">
              <v-autocomplete
                v-model="search"
                :items="eventos"
                label="Evento"
                item-text="title"
                item-value="id"
                no-data-text="Nenhum evento encontrado"
                auto-select-first
                hide-details
                hide-selected
              ></v-autocomplete>
            </v-col>
            <v-col v-show="!adicionar" cols="12" sm="2" md="2">
            <v-btn v-on:click="search='n'" color="#0c2a69" dark>Ver Todos</v-btn>
          </v-col>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="comunicados"
            :page.sync="page"
            :items-per-page="10"
            :sort-by="'id'"
            :sort-desc= true
            hide-default-footer
            v-show="!adicionar"
            class="elevation-1 estilo-tabela"
            @page-count="pageCount = $event"
            :loading="loading"
            loading-text="Carregando...">

          <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="580px">
              <v-card>
                <v-card-title class="headline">Tem certeza que quer excluir esse comunicado?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item="{ item }">
              {{terminaLoading()}}
              <tr :v-if="search !== 'n'" v-show="item.id_evento === search" class="text-left">
                <td>{{item.id}}</td>
                <td>{{item.titulo}}</td>
                <td>{{item.descricao}}</td>
                <td>{{formataData(item.data)}}</td>
                <td><span v-if="item.evento != null">{{item.evento.title}}</span></td>
                <td><span v-if="item.etapa_evento != null">{{item.etapa_evento.title}}</span></td>
                <td>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  @click="editItem(item)">
                  mdi-pencil
                  </v-icon>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  @click="deleteItem(item)">
                  mdi-delete
                  </v-icon>
                </td>
              </tr>
              <tr v-show="search == 'n'" class="text-left">
                <td>{{item.id}}</td>
                <td>{{item.titulo}}</td>
                <td>{{item.descricao}}</td>
                <td>{{formataData(item.data)}}</td>
                <td><span v-if="item.evento != null">{{item.evento.title}}</span></td>
                <td><span v-if="item.etapa_evento != null">{{item.etapa_evento.title}}</span></td>
                <td>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  @click="editItem(item)">
                  mdi-pencil
                  </v-icon>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  @click="deleteItem(item)">
                  mdi-delete
                  </v-icon>
                </td>
              </tr>
            </template>

          <template v-slot:no-data>
            <h4 class="mb-1 mt-1">Lista de comunicados vazia</h4>
          </template>
        </v-data-table>
      </v-card>
    <div v-show="!adicionar" class="text-center pt-2">
      <v-pagination
          class="pagination"
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
    </div>
    <DefaultDialog
        :dialog="dialog"
        :title="(editar != true)?'Adicionado com sucesso':'Editado com sucesso'"
        :text="(editar != true)?'Seu comunicado foi adicionado com sucesso.':'Seu comunicado foi editado com sucesso.'"
        @update:closeDialog="dialog = $event"
    />
    <DefaultDialog
        :dialog="dialogErro"
        :title="'Ocorreu um erro'"
        :text="erro + 'Não foi possível cadastrar/editar esse comunicado, tente novamente ou abra um chamado na Schaffen.'"
        @update:closeDialog="dialogErro = $event"
    />
  </v-card>
</template>

<script>
import axios from "../../services/axios";
import moment from "moment";

export default {
  name: 'AdmComunicados',
  components: {
    DefaultDocumentInput: () => import("@/components/DefaultComponents/DefaultDocumentInput"),
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
    DefaultDatePicker: () => import("@/components/DefaultComponents/DefaultDatePicker"),
    DefaultDialog: () => import("@/components/DefaultComponents/DefaultDialog"),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogDelete: false,
      dialogErro: false,
      adicionar: false,
      editar: false,
      search: 'n',
      search_aux: 'n',
      labelData: 'Data',
      enviaPush: true,
      noDataCont: 0,
      page: 1,
      pageCount: 0,
      etapaMensagem: 'Selecione um evento',
      headers: [
        {text: 'Código', sortable: false, value: 'id'},
        {text: 'Titulo', sortable: false, value: 'titulo'},
        {text: 'Descrição', value: 'descricao', sortable: false},
        {text: 'Data', sortable: true, value: 'data'},
        {text: 'Evento', value: 'evento.title', sortable: false},
        {text: 'Etapa', value: 'etapa_evento.title', sortable: false},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      comunicados: [],
      eventos: [],
      etapas: [],
      tipoEventos: [],
      erro: '',
      editedIndex: -1,
      editedItem: {
        codigo: '',
        titulo: '',
        descricao: '',
        data: '',
        id_evento: '',
        id_etapa: [],
        enviaPush: true,
      },
      defaultItem: {
        codigo: '',
        titulo: '',
        descricao: '',
        data: '',
        id_evento: '',
        id_etapa: [],
        enviaPush: true,
      },
      eventoTodos: {
        id: -1,
        title: "Todos os eventos",
        event_stages:[{
          id: -1,
          title: "Todas as etapas",
        }]
      },
      etapaTodas: {
        id: -1,
        title: "Todas as etapas",
      },
    }
  },
  watch:{
    'editedItem.id_evento'(){
        for(var i = 0; i<this.eventos.length; i++){
          if(this.eventos[i].id == this.editedItem.id_evento){
            this.etapas = this.eventos[i].event_stages;
            this.etapaMensagem = 'Evento não possui etapas cadastradas';
            if(this.etapas.length > 1){
              this.etapas.splice(this.etapas.length, 0, this.etapaTodas);
            }
            if(this.etapas.length == 1){
              this.editedItem.id_etapa = this.etapas[0];
            }
          } 
        }
      },
  },
  mounted() {
    this.inicializa()
  },
  methods: {
    inicializa() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.loading = true,
      this.labelData = "Data"
      axios
        .get("/comunicados")
        .then((response) => {
          this.comunicados = response.data.data;
          console.log()
          if(response.data.data.length == 0)this.terminaLoading();
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/eventos?with[]=event_stages")
        .then((response) => {
          this.eventos = response.data.data;
          this.eventos.splice(this.eventos.length, 0, this.eventoTodos);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/events-type")
        .then((response) => {
          this.tipoEventos = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formataData(data) {
      return moment(String(data)).format('DD/MM/YYYY')
    },
    terminaLoading(){
      this.loading = false
    },
    adicionarComunicado() {
      this.adicionar = !this.adicionar;
      this.editar = false;
      this.labelData = "Data";
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    salvarNovoComunicado() {
      if (this.editedItem.titulo != '' && this.editedItem.descricao != '' && this.editedItem.data != null && this.editedItem.tipo_evento_id != '' && this.editedItem.id_evento != null) {
        console.log(this.editedItem.id_etapa)
        const dados = {
          "id": this.editedItem.codigo,
          "title": this.editedItem.titulo,
          "description": this.editedItem.descricao,
          "date": this.editedItem.data,
          "event_id": this.editedItem.id_evento,
          "event_stage_id": this.editedItem.id_etapa.id,
          "send": this.editedItem.enviaPush,
        };
        if (this.editar != true) {
          axios
            .post(
              "/comunicados",
              dados,
            )
            .then(response => {
              if (response === undefined) {
                this.erro = "A descrição ultrapassa a quantidade permitida de caracteres, 255. ";
                this.dialogErro = true;
              } else{
              this.inicializa();
              this.erro = false;
              this.adicionarComunicado();
              }
            })
            .catch(error => {
              console.log(error);
              this.dialogErro = true;
            });
        } else {
          axios
            .put(
              "/comunicados/" +
                this.editedItem.codigo,
              dados,
            )
            .then(response => {
              if (response === undefined) {
                this.erro = "A descrição ultrapassa a quantidade permitida de caracteres, 540. ";
                this.dialogErro = true;
              } else{
              this.inicializa();
              this.erro = false;
              this.adicionarComunicado();
              }
            })
            .catch(error => {
              console.log(error);
              this.dialogErro = true;
            });
        }
      }
    },
    deleteItem(item) {
      this.editedIndex = this.comunicados.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    editItem(item) {
      if (this.adicionar == false) this.adicionar = true
      this.editedItem.codigo = item.id
      this.editedItem.titulo = item.titulo
      this.editedItem.descricao = item.descricao
      this.editedItem.data = item.data
      this.editedItem.id_evento = item.evento.id
      this.editedItem.id_etapa = item.etapa_evento
      this.editedItem.enviaPush = item.enviado
      this.labelData = this.formataData(this.editedItem.data)

      this.editar = true
    },
    deleteItemConfirm() {
      axios
        .delete(
          "/comunicados/" +
            this.comunicados[this.editedIndex].id
        )
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
      this.comunicados.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      })
    },
  }
}
</script>

<style scoped>
*{
  padding: 0px;
  margin: 0px;
}

.estilo-tabela {
  width: 100% !important;
}

.pagination > ul > li > button > i {
  font-size: 45px !important;
}

.align-right{
  text-align: right;
}

.switch{
  padding-left: 13px;
}
</style>
