<template>
  <div class="d-flex flex-column align-center">
    <div class="adiciona-button">
        <v-col v-show="!adicionar" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarBanner" color="#0c2a69" dark>Adicionar banner</v-btn>
        </v-col>
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3">Banners</h1>
        </v-col>
    </div>
    <div v-if="adicionar" style="width:85%">
      <v-row class="mb-5">
        <v-col cols="12" sm="12" md="12">
          <v-text-field
              label="Título"
              v-model="defaultItem.titulo"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <DefaultDocumentInput
              text="Anexar imagem para o banner"
              :required="true"
              type="banner"
              module="defaultItem.imagem"
              reqText="Selecione o arquivo"
              @update:returnFile="defaultItem.imagem = $event"
          />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarBanner()"></DefaultButton>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                         @click.native="salvarNovoBanner()"></DefaultButton>
        </v-col>
      </v-row>
    </div>
    <v-data-table
        :headers="headers"
        :items="banners"
        :page.sync="page"
        :items-per-page="7"
        hide-default-footer
        v-show="!adicionar"
        class="elevation-1 estilo-tabela"
        @page-count="pageCount = $event">
      <template v-slot:top>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Tem certeza que quer excluir esse banner?</v-card-title>
            <v-car
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
            </v-car>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2 icon_acoes"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            class="icon_acoes"
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <h4 class="mb-1 mt-1">Lista de banners vazia</h4>
      </template>
    </v-data-table>
    <div v-show="!adicionar" class="text-center pt-2">
      <v-pagination
          class="pagination"
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import axios from '../../services/axios';

export default {
  name: 'AddBanners',
  components: {
    DefaultDocumentInput: () => import("@/components/DefaultComponents/DefaultDocumentInput"),
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
  },
  data: () => ({
    adicionar: false,
    editar: false,
    dialog: false,
    dialogDelete: false,
    page: 1,
    pageCount: 0,
    headers: [
      {text: 'Titulo do banner', value: 'titulo', sortable: false,},
      {text: 'Imagem', value: 'imagem', sortable: false,},
      {text: 'Ações', value: 'actions', sortable: false},
    ],
    banners: [],
    editedIndex: -1,
    editedItem: {
      titulo: '',
      imagem: '',
      codigo: -1
    },
    defaultItem: {
      titulo: '',
      imagem: '',
      codigo: -1
    },
  }),
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions(["action_get_banners"]),
    initialize() {
      this.action_get_banners().then(response => {
        // if (response) {
        //   this.banners = response.data.data
        // }
      });
    },
    adicionarBanner() {
      this.adicionar = !this.adicionar;
      if (this.editar == true) this.editar = false
    },
    salvarNovoBanner() {
      const dados = {
        "title": this.defaultItem.titulo,
        "file_name": this.defaultItem.imagem[0].name,
        "image": this.defaultItem.imagem[0].base64,
      }
      if (this.editar != true) {
        axios
            .post('/banners', dados, {
              headers: {}
            })
            .then(response => {
              this.initialize()
            })
            .catch(error => {
              console.log(error)
            })
        this.adicionar = !this.adicionar;
      } else {
        axios
            .put('/banners/' + this.defaultItem.codigo, dados, {
              headers: {}
            })
            .then(response => {
              this.initialize()
            })
            .catch(error => {
              console.log(error)
            })
        this.adicionar = !this.adicionar;
      }
      this.defaultItem = {
        titulo: '',
        imagem: '',
        codigo: -1
      };
    },
    deleteItem(item) {
      this.editedIndex = this.banners.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    editItem(item) {
      if (this.adicionar == false) this.adicionar = true
      this.defaultItem.titulo = item.titulo
      this.defaultItem.imagem = item.imagem
      this.defaultItem.texto = item.texto
      this.defaultItem.codigo = item.codigo
      this.editar = true
    },
    deleteItemConfirm() {
      axios
          .delete('/banners/' + this.banners[this.editedIndex].codigo)
          .then(response => {
          })
          .catch(error => {
            console.log(error)
          })
      this.banners.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>

<style scoped>

.estilo-tabela {
  width: 100% !important;
}
</style>
