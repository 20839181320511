<template>
  <div class="home">
    <v-app-bar app class="cor-menu tamanho-barra borda">
      <div class="d-flex align-center ml-7">
        <v-img
          alt="Vuetify Logo"
          class="shrink ml-5"
          contain
          src="@/assets/images/logo.png"
          transition="scale-transition"
          width="148"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        class="hidden-sm-and-down"
        text
        @click="navigateTo('login')"
        
      >
        <span class="mr-2 cor-branca">LOGIN</span>
      </v-btn>
      <v-btn
        class="hidden-sm-and-down"
        v-for="(item, i) in itens"
        :key="i"
        text
        @click="$vuetify.goTo(item.id)"
      >
        <span class="mr-2 cor-branca">{{ item.nome }}</span>
      </v-btn>
      <div class="hidden-md-and-up">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="white" icon v-bind="attrs" v-on="on">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in itens" :key="index">
              <v-list-item-title @click="$vuetify.goTo(item.id)">{{
                item.nome
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
      <Banners :banners="banners"/>
      <FaleConosco id="faleconosco" />
      <Rodape />
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import Banners from "@/components/LandingPage/Banners.vue";
import FaleConosco from "@/components/LandingPage/FaleConosco.vue";
import Rodape from "@/components/LandingPage/Rodape.vue";

export default {
  name: "Home",
  components: {
    Banners,
    FaleConosco,
    Rodape,
  },
  mounted() {
    this.inicializa();
  },
  data: () => ({
    diferenciais: [],
    banners: [],
    fab: false,
    itens: [
      {
        nome: "Fale conosco",
        id: "#faleconosco",
      },
    ],
  }),
  methods: {
    inicializa() {
    },
    navigateTo(where) {
        if(this.$router.history.current.path != ('/' + where)){
        this.$router.push({path: where})
        }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style>
.v-toolbar__content,
.v-toolbar__extension {
  height: 110px !important;
}

.cor-menu {
  background-color: #08235C !important;
}

.cor-branca {
  color: white;
}

.tamanho-barra {
  height: 113px !important;
}

.borda {
  border-bottom: 5px solid #010101 !important;
}
</style>
