import { render, staticRenderFns } from "./FaleConosco.vue?vue&type=template&id=091ede54&"
import script from "./FaleConosco.vue?vue&type=script&lang=js&"
export * from "./FaleConosco.vue?vue&type=script&lang=js&"
import style0 from "./FaleConosco.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VRow,VTextField,VTextarea})
