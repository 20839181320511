<template>
  <div class="cor-fundo">
    <h2 class="cor-branca-texto titulo-propaganda tamanho-fonte">Fale conosco</h2>
    <v-row class="ml-15 mr-15 mb-3">
      <v-col cols="12" sm="6" md="6">
        <v-text-field
            label="Nome"
            class="style-input .v-input__slot"
            hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
            label="Email"
            class="style-input .v-input__slot"
            hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="ml-15 mr-15 pb-10">
      <v-col cols="12" sm="12" md="12">
        <v-textarea
            label="Mensagem"
            class="style-input .v-input__slot"
            hide-details="auto"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="ml-15 mr-15 pb-10">
      <DefaultButton text="Enviar" @click.native="enviarEmail()"></DefaultButton>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'FaleConosco',
  components: {
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
  },
  data: () => ({
    corpoEmail: 'corpo do email'
  }),
  methods: {
    enviarEmail(){
      window.open('mailto:hugo.petla@schaffen-it.com?subject=Contato com a escola pelo site&amp;body=Olá,%0D%0A%0D%0A['+ this.corpoEmail+']%0D%0A%0D%0AObrigado pela atenção');
    }
  }
}
</script>

<style>
.cor-fundo {
  background-color: #08235C
}

.cor-branca-texto {
  color: white;
  text-align: center;
  padding-top: 3%;
  padding-bottom: 2%;
}

.style-input {
  background-color: white;
  border-radius: 13px;
}

.v-input__slot {
  left: 11px;
  width: 96% !important;
}
</style>
