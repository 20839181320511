<template>
  <v-card class="d-flex flex-column align-center pa-5 max-size">
    <div class="adiciona-button">
        <!--<v-col v-show="!adicionar && !mostraDetalhes" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarUsuario" color="#98C238" dark>Adicionar Usuário</v-btn>
        </v-col>-->
        <br>
        <br>
        <v-col v-show="mostraDetalhes" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="voltar()" color="#0c2a69" dark>Voltar</v-btn>
        </v-col>
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3" style="padding-top:50px" v-if="adicionar && !mostraDetalhes" >Adicionar Usuário</h1>
          <h1 class="mb-3" v-if="!adicionar && !mostraDetalhes" >Usuários do App</h1>
          <h1 class="mb-3 pa-3 text-center" v-if="mostraDetalhes">Detalhes do usuário</h1>
        </v-col>
    </div>
    <div v-if="adicionar && !mostraDetalhes" style="width:85%">
        <v-row class="pa-3">
          <v-col cols="12" sm="6" md="6">
            <v-text-field
                label="Nome"
                v-model="editedItem.nome"
                outlined
                hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
                label="E-mail"
                v-model="editedItem.email"
                outlined
                :disabled="editar"
                hide-details="auto"
                :rules="emailRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-3">
          <v-col cols="12" sm="6" md="6">
            <v-text-field
                label="Senha"
                v-model="editedItem.senha"
                outlined
                :disabled="editar"
                hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
                label="Perfil"
                v-model="perfil"
                outlined
                hide-details
                disabled
            ></v-text-field>
          </v-col>
        </v-row>
  
        
        <v-row class="pa-3">
          <v-col cols="12" sm="6" md="6" class="pa-2">
            <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarUsuario()"></DefaultButton>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pa-2">
            <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                          :disable="(this.editedItem.nome != '' && this.editedItem.email != '')?false:true"
                          @click.native="salvarNovoUsuario()"></DefaultButton>
          </v-col>
        </v-row>
    </div>
    <div style="width:85%" v-show="!adicionar && !mostraDetalhes">
      <v-card>
        <v-card-title style="" >
          <v-col cols="12" sm="2" md="2" class="align-right">
            <span >Filtrar:</span>
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="12" sm="8" md="8" class="pa-4">
              <v-autocomplete
                v-model="search"
                :items="usuarios"
                label="Nome "
                item-text="name"
                item-value="name"
                no-data-text="Nenhum usuário encontrado"
                auto-select-first
                hide-details
                hide-selected
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2">
            <v-btn v-on:click="search='Usuário'" color="#0c2a69" dark>Ver Todos</v-btn>
          </v-col>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="usuarios"
            :page.sync="page"
            :items-per-page="7"
            :sort-by="'id'"
            item-key="codigo"
            v-show="!adicionar"
            hide-default-footer
            class="elevation-1 estilo-tabela"
            @page-count="pageCount = $event"
            :loading="loadingUsuarios"
            loading-text="Carregando..."
            :search="search">

            

            <template v-slot:top>
                        <v-dialog v-model="dialogDelete" max-width="550px">
                          <v-card>
                            <v-card-title class="headline">Tem certeza que quer excluir esse usuário?</v-card-title>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                              <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                          <!--<v-dialog v-model="dialogSenha" max-width="550px">
                            <v-card>
                              <v-card-title class="headline">Tem certeza que quer redefinir esta senha?</v-card-title>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeResetSenha">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="editSenhaConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>-->
                        </template>

            <template v-slot:item="{ item }">
                <tr v-show="item.profile == 1">
                  <td class="text-center">{{item.id}}</td>
                  <td class="text-left">{{item.name}}</td>
                  <td class="text-left">{{item.username}}</td>
                  <td class="text-left">{{item.email}}</td>
                  <td class="text-left">{{formataData(item.created_at)}}</td>
                  <td class="text-left">
                    <span v-if="item.assosciado == true">
                      Sim
                    </span>
                    <span v-else>
                      Não
                    </span>
                    </td>
                  <td class="text-left">{{item.profile_name}}</td>
                  <td class="text-left">
                    {{terminaLoadingUsuarios()}}
                    <v-icon
                        small
                        class="mr-2 icon_acoes"
                        title="Editar"
                        @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        class="mr-2 icon_acoes"
                        title="Excluir"
                        @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                    <v-icon
                        small
                        class="icon_acoes"
                        title="Detalhes"
                        @click="detalhes(item.id)">
                        mdi-magnify
                    </v-icon>
                  </td>
                </tr>
              </template>

            <template v-slot:no-data>
              <h4 class="mb-1 mt-1">Lista de usuários vazia</h4>
            </template>
            
        </v-data-table>
      </v-card>
      <div v-show="!adicionar" class="text-center pt-2">
        <v-pagination
            class="pagination"
            v-model="page"
            :length="pageCount"
        ></v-pagination>
      </div>
    </div>

    <div style="width:85%;" v-show="mostraDetalhes" class="text-left">
      <div v-if="usuarioDetalhesCarregado && usuarioDetalhesEventosCarregado">
        <div>
        <div class="box pa-3 ma-lados bgcolorbox tam-detalhes mb-5">
          <span><b>Nome: </b>{{usuarioDetalhes.name}}</span><br>
          <span><b>Email: </b>{{usuarioDetalhes.email}}</span><br>
          <span><b>Usuário: </b>{{usuarioDetalhes.username}}</span><br>
          <span><b>Data de criação: </b>{{formataData(usuarioDetalhes.created_at)}}</span><br>
          <span><b>Código: </b>{{usuarioDetalhes.id}}</span><br>
          
        </div>
        <div class="box pa-3 ma-lados bgcolorbox tam-socio text-center">
            <span v-if="usuarioDetalhes.assosciado == true">
              <div ><b>Sócio</b></div>
              <img src="../../assets/images/logoCTH.png" alt="" width="139">
            </span>
            <span v-else>
              <div ><b>Não Sócio</b></div>
              <img src="../../assets/images/naoSocio.png" alt="" width="89">
            </span>
            <br>
        </div>
        <br>
        <div class="ml-20 pa-2 text-center box3 ma-left">
          <button @click="mostraEventos()">
          <h4>Eventos</h4>
          </button>
        </div>
        <div class="pa-2 text-center box4">
          <button @click="mostraDocumentos()">
          <h4>Documentos</h4>
          </button>
        </div>
        <div class="box2 ma-lados mb-4">
          <div class="text-center" v-if="mostraDocumento">
            <div v-if="usuarioDetalhes.documents != undefined">
              <div v-if="usuarioDetalhes.documents.length == 0" class="text-center pa-3"> Este usuário não possui documentos cadastrados!</div>
              <div v-else v-for="documento in usuarioDetalhes.documents" :key="documento.description" class="documentos pa-2">
                <v-card>
                  {{documento.description}}<br>
                  <img :src="'data:image/png;base64,' + documento.image"  width="210" height="150" @click="openIMG(documento.image)"></img>
                </v-card>
              </div>
            </div>
          </div>
          <v-data-table
                v-if="mostraEvento"
                :headers="headersDetalhes"
                :items="usuarioDetalhesEventos.events"
                :sort-by="'id'"
                :sort-desc= true
                hide-default-footer
                class="elevation-1 estilo-tabela">

                <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-center">{{item.events_id}}</td>
                      <td class="text-left">{{item.title}}</td>
                      <td class="text-left">{{item.type}}</td>
                      <td class="text-left">{{item.voucher_id}}</td>
                      <td class="text-left">{{item.price_to_pay}}</td>
                      <td class="text-left">
                        <span v-if="item.paid == 0">
                          Pendente
                        </span>
                        <span v-else>
                          OK
                        </span>
                      </td>
                      <td class="text-left"> 
                        <v-icon
                        v-if="item.paid == 0"
                        class="icon_acoes"
                        title="Confirmar Pagamento"
                        @click="confirmarPagamento(item)">
                          mdi-currency-usd
                        </v-icon>
                        <v-icon
                        v-if="item.paid != 0"
                        class="icon_acoes"
                        title="Cancelar Pagamento"
                        @click="cancelarPagamento(item)">
                          mdi-currency-usd-off
                        </v-icon>
                      </td>
                    </tr>
                  </template>

                <template v-slot:no-data>
                  <h4 class="mb-1 mt-1">Usuário não participou de nenhum evento</h4>
                </template>
                
            </v-data-table>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <v-icon
            class="mr-2 icon_acoes mdi-spin">
            mdi-chart-donut
        </v-icon>
        Carregando informações...</div>
    </div>
    <DefaultDialog
        :dialog="dialog"
        :title="(editar != true)?'Adicionado com sucesso':'Editado com sucesso'"
        :text="(editar != true)?'O usuário foi adicionado com sucesso.':'O usuário foi editado com sucesso.'"
        @update:closeDialog="dialog = $event"
    />
  </v-card>
</template>


<script>
import axios from "../../services/axios";
import moment from "moment";

export default {
  name: 'AddUsuarios',
  components: {
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
    DefaultDialog: () => import("@/components/DefaultComponents/DefaultDialog"),
  },
  data() {
    return {
      loadingUsuarios: false,
      dialog: false,
      //dialogSenha: false,
      mostraDetalhes: false,
      dialogDelete: false,
      adicionar: false,
      editar: false,
      mostraDocumento: false,
      mostraEvento: true,
      page: 1,
      pageCount: 0,
      search: 'Usuário',
      perfil: 'Usuário',
      headers: [
        {text: 'Código',value: 'id', width: "13%"},
        {text: 'Nome',value: 'name'},
        {text: 'Usuário ',value: 'username'},
        {text: 'E-mail', value: 'email', sortable: true},
        {text: 'Data cadastro', value: 'created_at', sortable: true},
        {text: 'Associado', value: 'customer', sortable: false},
        {text: 'Tipo', value: 'profile_name', sortable: true},
        {text: 'Ações', value: 'actions', sortable: false, width: '15%'},
      ],
      headersDetalhes: [
        {text: 'Código',value: 'events_id'},
        {text: 'Evento',value: 'title'},
        {text: 'Tipo', value: 'type', sortable: true},
        {text: 'Voucher', value: 'voucher_id', sortable: true},
        {text: 'Valor', value: 'price_to_pay', sortable: true},
        {text: 'Pagamento', value: 'paid', sortable: true},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      emailRules: [
      (v) => !!v || "Digite um E-mail",
      (v) => /.+@.+/.test(v) || "O E-mail precisa ser válido",
      ],
      usuarios: [],
      usuarioDetalhes: [],
      usuarioDetalhesEventos: [],
      user: {},
      usuarioDetalhesEventosCarregado: false,
      usuarioDetalhesCarregado: false,
      editedIndex: -1,
      editedItem: {
        nome: '',
        usuario: '',
        email: '',
        codigo: '',
        senha: '',
        created_at: '',
      },
      defaultItem: {
        nome: '',
        usuario: '',
        email: '',
        codigo: '',
        senha: '',
        created_at: '',
      },
    }
  },
  mounted() {
    this.inicializa()
  },
  methods: {
    inicializa() {
      this.loadingUsuarios = true,
      axios
        .get("/usuarios")
        .then((response) => {
          this.usuarios = response.data.data.map((usuarios) => ({
            ...usuarios, // Association, same as Object.assign
          }));
          if(response.data.data.length == 0)this.terminaLoading();
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("/user")
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    adicionarUsuario() {
      this.adicionar = !this.adicionar;
      if (this.editar == true) this.editar = false;
      this.editedItem = this.defaultItem;
    },
    salvarNovoUsuario() {
      if (this.editedItem.nome != '' && this.editedItem.email != '') {
        this.dialog = true
        const dados = {
          "id": this.editedItem.codigo,
          "name": this.editedItem.nome,
          "username": this.editedItem.email,
          "email": this.editedItem.email,
          "password": this.editedItem.senha,
          "profile": '0',
        };
        if (this.editar != true) {
          axios
            .post(
              "/usuarios",
              dados,
              {
                headers: {},
              }
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
            this.adicionar = !this.adicionar;
        } else {
          axios
            .put("/usuarios/" +
              this.editedItem.codigo,
              dados,
              {headers: {},}
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
          this.adicionar = !this.adicionar;
        }
      }
    },
    deleteItem(item) {
      this.editedIndex = this.usuarios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    /*editSenha(item) {
      this.editedIndex = this.usuarios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogSenha = true
    },
    editSenhaConfirm() {
      this.dialogSenha = false
      const dados = {
          "user_id": this.editedItem.codigo,
        };
          axios
            .post("/usuarios/reset-password",
              dados,
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
    },*/
    editItem(item) {
      if (this.adicionar == false) this.adicionar = true
      this.editedItem.codigo = item.id
      this.editedItem.nome = item.name
      this.editedItem.usuario = item.username
      this.editedItem.email = item.email
      this.editar = true
    },
    deleteItemConfirm() {
      axios
          .delete(
              "/usuarios/" +
              this.usuarios[this.editedIndex].id
          )
          .then(() => {
          })
          .catch((error) => {
            console.log(error);
          });
      this.usuarios.splice(this.editedIndex, 1)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    /*closeResetSenha() {
      this.dialogSenha = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },*/
    terminaLoadingUsuarios(){
      this.loadingUsuarios = false
    },
    detalhes(id){
      axios
        .get("/usuarios/" +
          id + "?with[]=documents"
        )
        .then((response) => {
          this.usuarioDetalhes = response.data.data;
          this.usuarioDetalhesCarregado = true;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("/usuarios/" +
          id + "?with[]=user_events"
        )
        .then((response) => {
          this.usuarioDetalhesEventos = response.data.data;
          this.usuarioDetalhesEventosCarregado = true;
        })
        .catch((error) => {
          console.log(error);
        });
        this.mostraDetalhes = true;
    },
    formataData(data) {
      return moment(String(data)).format('DD/MM/YYYY')
    },
    formataValor(valor){
      return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    voltar(){
      this.mostraDetalhes = false;
      this.usuarioDetalhesEventosCarregado = false;
      this.usuarioDetalhesCarregado = false;
    },
    openIMG(imagem){
      var win = window.open();
      win.document.write("<img style=\"margin:auto;width:100%;\" src='data:image/png;base64,"+imagem+"'/>");
    },
    mostraEventos(){
      this.mostraEvento = true;
      this.mostraDocumento = false;
    },
    mostraDocumentos(){
      this.mostraEvento = false;
      this.mostraDocumento = true;
    },
    confirmarPagamento(item){
      const dados = {
          "user_event_id": item.id,
        };
      axios
        .post(
          "/paid-events-user",
          dados,
        )
        .then(response => {
          axios
            .get("/usuarios/" +
              this.usuarioDetalhesEventos.id + "?with[]=user_events"
            )
            .then((response) => {
              this.usuarioDetalhesEventos = response.data.data;
            })
            .catch((error) => {
              console.log(error);
            });

          var description = "Taxa de inscrição do usuário " + this.usuarioDetalhesEventos.name;
          if(item.voucher_id != null){
            description = description + ", Voucher: " + item.voucher_id;
          }
          const dados2 = {
          "user_id": this.user.id,
          "events_id": item.events_id,
          "description": description,
          "payday": new Date(),
          "category": "C",
          "value": item.price_to_pay,
        };
          axios
          .post(
            "/financeiro",
            dados2,
          )
          .then(response => {
          })
          .catch(error => {
            console.log(error);
            this.dialogErro = true;
          });
      })
      .catch(error => {
        console.log(error);
      });
    },
    cancelarPagamento(item){
      const dados = {
          "user_event_id": item.id,
        };
      axios
        .post(
          "/paid-events-user",
          dados,
        )
        .then(response => {
          axios
            .get("/usuarios/" +
              this.usuarioDetalhesEventos.id + "?with[]=user_events"
            )
            .then((response) => {
              this.usuarioDetalhesEventos = response.data.data;
            })
            .catch((error) => {
              console.log(error);
            });
          var description = "Cancelamento de taxa de inscrição do usuário " + this.usuarioDetalhesEventos.name;
          if(item.voucher_id != null){
            description = description + ", Voucher: " + item.voucher_id;
          }

          const dados2 = {
          "user_id": this.user.id,
          "events_id": item.events_id,
          "description": description,
          "payday": new Date(),
          "category": "D",
          "value": item.price_to_pay,
        };
          axios
          .post(
            "/financeiro",
            dados2,
          )
          .then(response => {
          })
          .catch(error => {
            console.log(error);
            this.dialogErro = true;
          });
      })
      .catch(error => {
        console.log(error);
      });
    },
  },
}
</script>

<style scoped>
.estilo-tabela {
  width: 100% !important;
}

.pagination > ul > li > button > i {
  font-size: 45px !important;
}

.status{
  width:100%;
  justify-content: center
}

.mdi-chevron-down{
  color: #ef4056 !important;
  height: 25px;
  width: 25px;
  font-size: 50px !important;
}

.selo{
  width: 10px;
}

.documentos{
  width: 240px;
  margin: auto;
  display: inline-block;
}

.box{
  border: solid 2px rgba(0, 0, 0, 0.500);
  border-radius: 0.25em;
  padding: 0px;
  margin-bottom: 20px;
  display: inline-block;
}

.box2{
  border: solid 2px rgba(0, 0, 0, 0.500);
  border-radius: 0em 0.25em 0.25em 0.25em;
}

.box3{
  border: solid 2px rgba(0, 0, 0, 0.500);
  border-radius: 0.25em 0em 0em 0em;
  background-color: rgb(236, 236, 236);
  width: 150px;
  display: inline-block;
}

.box4{
  border: solid 2px rgba(0, 0, 0, 0.500);
  border-radius: 0em 0.25em 0em 0em;
  background-color: rgb(236, 236, 236);
  width: 150px;
  display: inline-block;
}

.ma-lados{
  margin: 0px 20px;
}

.bgcolorbox{
  background-color: rgb(236, 236, 236);
}

.box3:hover{
  background-color: rgb(175, 175, 175);
}

.box4:hover{
  background-color: rgb(175, 175, 175);
}

.ma-left{
  margin-left: 20px;
}

.tam-detalhes{
  width: 60%;
}

.tam-socio{
  width: 30%;
}
</style>
