import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import AddBanners from '../views/admin/AddBanners.vue'
import AddAlbuns from '../views/admin/AddAlbuns.vue'
import AdmComunicados from "@/views/admin/AdmComunicados";
import AddUsuarioPortal from "@/views/admin/AddUsuarioPortal";
import AddUsuarioApp from "@/views/admin/AddUsuarioApp";
import AdmEventos from "@/views/admin/AdmEventos";
import AdmFinanceiro from "@/views/admin/AdmFinanceiro";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/admin',
        name: 'Admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
        children: [
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: '/banners',
                component: AddBanners
            },
            {
                path: '/albuns',
                component: AddAlbuns
            },
            {
                path: '/comunicados',
                component: AdmComunicados
            },
            {
                path: '/usuariosPortal',
                component: AddUsuarioPortal
            },
            {
                path: '/usuariosApp',
                component: AddUsuarioApp
            },
            {
                path: '/eventos',
                component: AdmEventos
            },
            {
                path: '/financeiro',
                component: AdmFinanceiro
            },
        ]
    },
]

const router = new VueRouter({
//    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
