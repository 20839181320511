<template>
  <v-card class="d-flex flex-column align-center pa-5 max-size">
   <div class="adiciona-button">
        <v-col v-show="!adicionar" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarMovimentacao" color="#0c2a69" dark>Adicionar Movimentação</v-btn>
        </v-col>
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3" style="padding-top:50px" v-if="adicionar" >Adicionar Movimentação</h1>
          <h1 class="mb-3" v-if="!adicionar" >Financeiro</h1>
        </v-col>
    </div>
    <div v-if="adicionar" style="width:85%">
        <v-row>
         <v-col cols="12" sm="12" md="12" class="pa-3">
             <v-autocomplete
                v-model="editedItem.evento_id"
                :items="eventos"
                label="Evento"
                item-text="title"
                item-value="id"
                no-data-text="Nenhum evento encontrado"
                auto-select-first
                outlined
                hide-details
                hide-selected
              ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row style="width: 97%;">
          <v-col cols="12" sm="4" md="4" class="pa-3">
            <DefaultDatePicker
              :label="'Data'"
              :data="(editedItem.data_pagamento == '')? '': formataData(editedItem.data_pagamento)"
              @update:returnDate="editedItem.data_pagamento = $event"/>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="pa-3">
            <v-select
                :items="categorias"
                hide-details
                label="Categoria"
                item-text="title"
                item-value="id"
                v-model="editedItem.categoria"
                outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="pa-3">
            <v-text-field
              type= number
              label="Valor"
              v-model="editedItem.valor"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-3">
          <v-col cols="12" sm="12" md="12">
            <v-textarea
                name="input-7-1"
                label="Descrição"
                v-model="editedItem.descricao"
                hint="Insira uma mensagem detalhada sobre a movimentação"
                outlined
            ></v-textarea>
          </v-col>
        <v-col cols="12" sm="6" md="6" class="pa-2">
          <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarMovimentacao()"></DefaultButton>
        </v-col>
        <v-col cols="12" sm="6" md="6" class="pa-2">
          <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                        :disable="(this.editedItem.evento_id != '' && this.editedItem.data_pagamento != '' && this.editedItem.categoria != '' && this.editedItem.valor != null && this.editedItem.descricao != '')?false:true"
                        @click.native="salvarNovoMovimentacao()"></DefaultButton>
        </v-col>
      </v-row>
    </div>
    <div style="width:85%">
      <v-card style="width:100%">
        <v-card-title style="" v-show="!adicionar">
          <v-col cols="12" sm="2" md="2" class="align-right">
            <span >Filtrar:</span>
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="12" sm="8" md="8" class="pa-4">
              <v-autocomplete
                v-model="search"
                :items="eventos"
                label="Evento"
                item-text="title"
                item-value="id"
                no-data-text="Nenhum evento encontrado"
                auto-select-first
                hide-details
                hide-selected
              ></v-autocomplete>
            </v-col>
            <v-col v-show="!adicionar" cols="12" sm="2" md="2">
            <v-btn v-on:click="search='n'" color="#0c2a69" dark>Ver Todos</v-btn>
          </v-col>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="movimentacoes"
            :sort-by="'id'"
            :sort-desc= true
            :page.sync="page"
            :items-per-page="10"
            :search="search_filtro"
            :loading="loading"
            hide-default-footer
            v-show="!adicionar"
            class="elevation-1 estilo-tabela"
            @page-count="pageCount = $event"
            loading-text="Carregando...">

          <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="580px">
              <v-card>
                <v-card-title class="headline">Tem certeza que quer excluir a movimentação?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item="{ item }">
              {{terminaLoading()}}
              <tr 
              :v-if="search !== 'n'" 
              v-show="item.evento.id === search"
              :style="getColor(item.categoria)">
                <td>{{item.id}}</td>
                <td class="text-left">{{item.evento.title}}</td>
                <td class="text-left">{{item.descricao}}</td>
                <td>{{formataData(item.data_pagamento)}}</td>
                <td>{{formataValor(item.valor)}}</td>
                <td>{{item.categoria}}</td>
                <td class="text-left">{{item.nome_usuario}}</td>
                <td>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  @click="editItem(item)">
                  mdi-pencil
                  </v-icon>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  @click="deleteItem(item)">
                  mdi-delete
                  </v-icon>
                </td>
              </tr>
              <tr v-show="search == 'n'" :style="getColor(item.categoria)">
                <td>{{item.id}}</td>
                <td class="text-left">{{item.evento.title}}</td>
                <td class="text-left">{{item.descricao}}</td>
                <td>{{formataData(item.data_pagamento)}}</td>
                <td>{{formataValor(item.valor)}}</td>
                <td>{{item.categoria}}</td>
                <td class="text-left">{{item.nome_usuario}}</td>
                <td>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  @click="editItem(item)">
                  mdi-pencil
                  </v-icon>
                  <v-icon
                  small
                  class="mr-2 icon_acoes"
                  @click="deleteItem(item)">
                  mdi-delete
                  </v-icon>
                </td>
              </tr>
            </template>

            <template v-slot:footer>
              <div class="total-financeiro" v-if="search != 'n'">
                Saldo Total do Evento: <div class="valor-total"  :style="getColorTotal(totalEvento())"><b>{{formataValor(totalEvento())}}</b></div>
              </div>
            </template>

          <template v-slot:no-data>
            <h4 class="mb-1 mt-1">Lista de movimentações vazia</h4>
          </template>
        </v-data-table>
      </v-card>
    <div v-show="!adicionar" class="text-center pt-2">
      <v-pagination
          class="pagination"
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
    </div>
    <DefaultDialog
        :dialog="dialog"
        :title="(editar != true)?'Adicionado com sucesso':'Editado com sucesso'"
        :text="(editar != true)?'Sua movimentação foi adicionada com sucesso.':'Sua movimentação foi editada com sucesso.'"
        @update:closeDialog="dialog = $event"
    />
    <DefaultDialog
        :dialog="dialogErro"
        :title="'Ocorreu um erro'"
        :text="erro + 'Não foi possível cadastrar/editar a movimentação, tente novamente ou abra um chamado na Schaffen.'"
        @update:closeDialog="dialogErro = $event"
    />
  </v-card>
</template>

<script>
import axios from "../../services/axios";
import moment from "moment";

export default {
  name: 'AdmFinanceiro',
  components: {
    DefaultDocumentInput: () => import("@/components/DefaultComponents/DefaultDocumentInput"),
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
    DefaultDatePicker: () => import("@/components/DefaultComponents/DefaultDatePicker"),
    DefaultDialog: () => import("@/components/DefaultComponents/DefaultDialog"),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogDelete: false,
      dialogErro: false,
      adicionar: false,
      editar: false,
      search: 'n',
      search_aux: 'n',
      search_filtro: '',
      enviaPush: true,
      noDataCont: 0,
      page: 1,
      pageCount: 0,
      headers: [
        {text: 'Código', sortable: false, value: 'id'},
        {text: 'Evento', value: 'evento.title', sortable: false},
        {text: 'Descrição', value: 'descricao', sortable: false},
        {text: 'Data', sortable: true, value: 'data_pagamento'},
        {text: 'Valor', sortable: true, value: 'valor'},
        {text: 'Categoria', sortable: true, value: 'categoria', width:"11%"},
        {text: 'Usuário', sortable: true, value: 'nome_usuario'},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      categorias:[
        {title: 'C - crédito/receita', id: "C"},
        {title: 'D - débito/despesa', id: "D"},
      ],
      movimentacoes: [],
      eventos: [],
      user: [],
      erro: '',
      editedIndex: -1,
      editedItem: {
        codigo: '',
        evento_id: '',
        descricao: '',
        data_pagamento: '',
        categoria: '',
        valor: null,
      },
      defaultItem: {
        codigo: '',
        evento_id: '',
        descricao: '',
        data_pagamento: '',
        categoria: '',
        valor: null,
      },
    }
  },
  watch:{
    search(){
      for(var i=0 ; i<this.eventos.length ; i++){
        if(this.search == this.eventos[i].id){
          this.search_filtro = this.eventos[i].title;
          return;
        }
      }
    }
  },
  mounted() {
    this.inicializa()
  },
  methods: {
    inicializa() {
      this.loading = true,
      axios
        .get("/financeiro")
        .then((response) => {
          this.movimentacoes = response.data.data;
          if(response.data.data.length == 0)
            this.terminaLoading();
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("/eventos")
        .then((response) => {
          this.eventos = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
       axios
        .get("/user")
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formataData(data) {
      return moment(String(data)).format('DD/MM/YYYY')
    },
    formataValor(valor){
      return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    terminaLoading(){
      this.loading = false
    },
    adicionarMovimentacao() {
      this.adicionar = !this.adicionar;
      this.editar = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    salvarNovoMovimentacao() {
      if (this.editedItem.evento_id != '' && this.editedItem.data_pagamento != '' && this.editedItem.categoria != '' && this.editedItem.valor != null && this.editedItem.descricao != '') {
         this.editedItem.valor.replace(",",".");
         console.log(this.user)
        const dados = {
          "user_id": this.user.id,
          "events_id": this.editedItem.evento_id,
          "description": this.editedItem.descricao,
          "payday": this.editedItem.data_pagamento,
          "category": this.editedItem.categoria,
          "value": this.editedItem.valor,
        };
        if (this.editar != true) {
          axios
            .post(
              "/financeiro",
              dados,
              {
                headers: {},
              }
            )
            .then(response => {
              if (response === undefined) {
                this.erro = "A descrição ultrapassa a quantidade permitida de caracteres, 540. ";
                this.dialogErro = true;
              } else{
              this.inicializa();
              this.adicionar = !this.adicionar;
              this.erro = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              }
            })
            .catch(error => {
              console.log(error);
              this.dialogErro = true;
            });
        } else {
          axios
            .put(
              "/financeiro/" +
                this.editedItem.codigo,
              dados,
              {
                headers: {},
              }
            )
            .then(response => {
              if (response === undefined) {
                this.erro = "A descrição ultrapassa a quantidade permitida de caracteres, 540. ";
                this.dialogErro = true;
              } else{
              this.inicializa();
              this.adicionar = !this.adicionar;
              this.erro = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              }
            })
            .catch(error => {
              console.log(error);
              this.dialogErro = true;
            });
        }
      }
    },
    deleteItem(item) {
      this.editedIndex = this.movimentacoes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    editItem(item) {
      if (this.adicionar == false) this.adicionar = true
      this.editedItem.codigo = item.id
      this.editedItem.evento_id = item.evento.id
      this.editedItem.descricao = item.descricao
      this.editedItem.data_pagamento = item.data_pagamento
      this.editedItem.categoria = item.categoria
      this.editedItem.valor = item.valor

      this.editar = true
    },
    deleteItemConfirm() {
      axios
        .delete(
          "/financeiro/" +
            this.movimentacoes[this.editedIndex].id
        )
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
      this.movimentacoes.splice(this.editedIndex, 1);
      this.closeDelete();
      axios
        .get("/eventos")
        .then((response) => {
          this.eventos = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    getColor(categoria) {
        if (categoria == "D") return 'background-color:rgb(243, 208, 208)'
        else if (categoria == "C") return 'background-color:rgb(217, 235, 217)'
      },
    getColorTotal(valor) {
        if (valor < 0){
          return 'color:red'
        } else if(valor > 0){ 
          return 'color:green'
        } else {
          return 'color:black'
        }
      },
    totalEvento(){
      for(var i = 0 ; i < this.eventos.length ; i++){
        if (this.eventos[i].id == this.search){
          return this.eventos[i].total_financeiros
        }
      }
    },
  },
}
</script>

<style scoped>
*{
  padding: 0px;
  margin: 0px;
}

.estilo-tabela {
  width: 100% !important;
}

.pagination > ul > li > button > i {
  font-size: 45px !important;
}

.align-right{
  text-align: right;
}

.switch{
  padding-left: 13px;
}

.total-financeiro{
  height: 50px;
  padding: 0px 30px;
  padding-top: 10px;
  font-size: 20px;
  text-align: center;
}

.valor-total{
  display: inline;
}
</style>
